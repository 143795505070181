import React from 'react'

// Notification component is extended from './action'
import PaperAction from './action'

/*
* Usage example
*
* <PaperNotification
*	color='primary | danger | warning'
*	icon='node | iconName'
*	title='node | string'
*	action='node'
*	onClose='function'
* >
* 	'node | string'
* </PaperNotification>
*
*/

function Notification(props) {
	const {
		icon,
		title,
		children,
		value,
		action,
		onClose,
		...rest
	} = props

	const paperProps = {
		icon: icon,
		title: title,
		action: action,
		onClose: onClose,
		border: true,
		opacity: true,
		...rest
	}

	return (
		<PaperAction {...paperProps}>
			{children || value}
		</PaperAction>
	)
}

export default Notification
