export default [
	{
		module: 'reset-password',
		actions: {
			dynamicPaths: [{
				keys: {
					':id': 2,
					':email': 3,
					':token': 4,
				},
				paths: {
					'/reset-password/:id/:email/:token': true
				}
			}]
		}
	}
	// }, {
]
