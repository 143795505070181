export default {
	module: 'landingPages',
	actions: {
		list: true,
		create: true,
		dynamicPaths: [
			{
				paths: {
					'/landingPages/edit/:id': true,
				},
				keys: {
					':id': 3
				}
			},
			{
				paths: {
					'/landingPages/cards': true,
				}
			},
			{
				paths: {
					'/landingPages/cards/create': true,
				}
			},
			{
				paths: {
					'/landingPages/cards/edit/:id': true,
				},
				keys: {
					':id': 4
				}
			},
			{
				paths: {
					'/landingPages/other-solutions': true,
				}
			},
			{
				paths: {
					'/landingPages/other-solutions/create': true,
				}
			},
			{
				paths: {
					'/landingPages/other-solutions/edit/:id': true,
				},
				keys: {
					':id': 4
				}
			}
		]
	}
}
