import _ from 'lodash'
import React from 'react'
// import PropTypes from 'prop-types'
import {
	compose,
} from 'recompose'
import {inject, observer} from 'mobx-react'

export const Enhancer = compose(
	inject('menu'),
	observer,
)

// passing menu.page to force rerender
export const Component = ({
	menu,
}) => {
	return _.map(menu.showingMenus, (Menu, idx) => <Menu key={'menu' + idx} active={menu.page} />)
}

export default Enhancer(Component)
