export default {
	module: 'guide-categories',
	actions: {
		list: true,
		details: true,
		create: true,
		dynamicPaths: [
			{
				paths: {
					'/guide-categories/edit/:id': true,
				},
				keys: {
					':id': 3
				}
			},
			{
				paths: {
					'/guide-categories/:id/orgs': true,
				},
				keys: {
					':id': 2
				}
			},
			{
				paths: {
					'/guide-categories/:id/items': true,
				},
				keys: {
					':id': 2
				}
			},
			{
				paths: {
					'/guide-categories/:id/guides': true,
				},
				keys: {
					':id': 2
				}
			},
			{
				paths: {
					'/guide-categories/:id/sort': true,
				},
				keys: {
					':id': 2
				}
			},
			{
				paths: {
					'/guide-categories/:id/partners': true,
				},
				keys: {
					':id': 2
				}
			},
			{
				paths: {
					'/guide-categories/:id/product-info': true,
				},
				keys: {
					':id': 2
				}
			},
			{
				paths: {
					'/guide-categories/:id/filter-rules': true,
				},
				keys: {
					':id': 2
				}
			}

		]
	}
}
