import {
	random,

} from 'lodash'

export const openTab = (url) => {
	let newTab = window.open(url, '_blank');
	newTab.focus()
}

/**
* Generate a random number of greater length than 15;
*
* @returns {string} random numbers and letters
*/
export const generateId = function() {
	var gen = function() {
		return Math.random().toString(36).slice(2) + random(999999);
	};

	var number;

	do {
		number = gen();
	} while (number.length < 15);

	return number;
}

/**
* Calculate percentage
*
* You don't need both remaining and complete, just one
*
* @param {object} data - eg: {
*                            total: 10,
*                            remaining: 7,
*                            complate: 3,
*                        }
*
* @returns {number} percentage, eg: 50
*/
export const calculatePercent = function(data) {
	var percent = 100;

	if (data.complate) {
		// not done
	}

	if (data.total !== 0) {
		// get percent remaining
		percent = (data.remaining / data.total) * 100;
		// round to nearest 5
		percent = Math.round(percent / 5) * 5;
		// convert to percent completed
		percent = 100 - percent;
	}

	return percent;
}

export const mapArrayToUpper = (data) => {
	return data.map((format) => format.toUpperCase())
}

export const mapArrayToUpperList = (data) => {
	return mapArrayToUpper(data).join(', ')
}
