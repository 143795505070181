import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'

const Components = {}

/**
*
* THIS FILE IS CALLED: common_svgs
*/

/**
* SVG Icon
* - generate markup for an svg icon
*/
export const Base = (props) =>
	<svg {..._.omit(props, 'iconName')}>
		<use xlinkHref={props.iconName}></use>
	</svg>
Components.Base = Base
Components.Base.displayName = 'ComponentsBase'
Base.propTypes = {
	iconName: PropTypes.string
}

// Dragonlaw Logo
export const Logo = ({size = 'medium'}) =>
	<Components.Base
		className={`icon icon--${size}`}
		iconName='#icon-dragonlaw'
	/>
Components.Logo = Logo
Components.Logo.displayName = 'ComponentsLogo'
Logo.propTypes = {
	size: PropTypes.string
}

// the three submenu dots, props for onClick
export const Dots = (props) =>
	<Components.Base
		{...props}
		className='icon icon--tiny icon--hover'
		iconName='images/sprites.svg#icon-dotmenu'
	/>
Components.Dots = Dots
Components.Dots.displayName = 'ComponentsDots'

export const Alert = () =>
	<Components.Base
		className='icon icon--large'
		iconName='#icon-info--color'
	/>
Components.Alert = Alert
Components.Alert.displayName = 'ComponentsAlert'

export const Info = ({size = 'medium'}) =>
	<Components.Base
		className={`icon icon--${size}`}
		iconName='#icon-info--color'
	/>
Components.Info = Info
Components.Info.displayName = 'ComponentsAlert'
Info.propTypes = {
	size: PropTypes.string
}

export const Back = ({icon}) =>
	<Components.Base
		className={'icon icon--medium'}
		iconName={icon ? icon : '#icon-back'}
	/>
Components.Back = Back
Components.Back.displayName = 'ComponentsBack'
Back.propTypes = {
	icon: PropTypes.string
}

export const CircleCross = () =>
	<Components.Base
		className={'icon icon--tiny icon-hover'}
		iconName='#icon-circlecross'
	/>
Components.CircleCross = CircleCross
Components.CircleCross.displayName = 'ComponentsCircleCross'

export const Cross = () =>
	<Components.Base
		className={'icon icon--tiny icon-hover'}
		iconName='#icon-cross'
	/>
Components.Cross = Cross
Components.Cross.displayName = 'ComponentsCross'

export const IconCross = () =>
	<Components.Base
		className={'icon icon--tiny icon-hover'}
		iconName='#icon-cross'
	/>
Components.Cross = Cross
Components.Cross.displayName = 'ComponentsCross'

export const Search = () =>
	<Components.Base
		className={'icon icon--tiny icon-hover'}
		iconName='#icon-search'
	/>
Components.Search = Search
Components.Search.displayName = 'ComponentsSearch'

export const Edit = ({size = 'medium'}) =>
	<Components.Base
		className={`icon icon--${size}`}
		iconName='#icon-edit'
	/>
Components.Edit = Edit
Components.Edit.displayName = 'ComponentsEdit'
Edit.propTypes = {
	size: PropTypes.string
}

export const Delete = ({size = 'medium'}) =>
	<Components.Base
		className={`icon icon--${size}`}
		iconName='#icon-delete'
	/>
Components.Delete = Delete
Components.Delete.displayName = 'ComponentsDelete'
Delete.propTypes = {
	size: PropTypes.string
}

export default Components
