export default {
	DTE: [
		{
			// front_end: 'https://api.dev.drabayashi.net/appinfo?app=dte-dev',
			// back_end: 'https://api.dev.drabayashi.net/dte/info',
			workflow: 'https://api.dev.drabayashi.net/workflow/info',
			authorization: 'https://api.dev.drabayashi.net/authorization/info',
			drive: 'https://api.dev.drabayashi.net/api/drive/info',
			dlserver: 'https://api.dev.drabayashi.net/info',
			env: 'dev'
		},
		{
			// front_end: 'https://api.dev.drabayashi.net/appinfo?app=dte-staging',
			// back_end: 'https://api.staging.drabayashi.net/dte/info',
			workflow: 'https://api.staging.drabayashi.net/workflow/info',
			authorization: 'https://api.staging.drabayashi.net/authorization/info',
			drive: 'https://api.staging.drabayashi.net/api/drive/info',
			dlserver: 'https://api.staging.drabayashi.net/info',
			env: 'staging'
		},
		{
			// front_end: 'https://api.dev.drabayashi.net/appinfo?app=dte-preprod',
			// back_end: 'https://api.beta.zegal.com/dte/info',
			workflow: 'https://api.beta.zegal.com/workflow/info',
			authorization: 'https://api.beta.zegal.com/authorization/info',
			drive: 'https://api.beta.zegal.com/api/drive/info',
			dlserver: 'https://api.beta.zegal.com/info',
			env: 'preprod'
		},
		{
			// front_end: 'https://api.dev.drabayashi.net/appinfo?app=dte',
			// back_end: 'https://api.zegal.com/dte/info',
			workflow: 'https://api.zegal.com/workflow/info',
			authorization: 'https://api.zegal.com/authorization/info',
			drive: 'https://api.zegal.com/api/drive/info',
			dlserver: 'https://api.zegal.com/info',
			env: 'prod'
		}
	],
	Admin: [
		{
			front_end: 'https://api.dev.drabayashi.net/appinfo?app=admin-dev',
			env: 'dev'
		},
		{
			front_end: 'https://api.dev.drabayashi.net/appinfo?app=admin-staging',
			env: 'staging'
		},
		{
			front_end: 'https://api.dev.drabayashi.net/appinfo?app=admin-preprod',
			env: 'preprod'
		},
		{
			front_end: 'https://api.dev.drabayashi.net/appinfo?app=admin',
			env: 'prod'
		}
	],
	Payments: [
		{
			front_end: 'https://api.dev.drabayashi.net/appinfo?app=payments-dev',
			back_end: 'https://api.dev.drabayashi.net/payments/info',
			env: 'dev'
		},
		{
			front_end: 'https://api.dev.drabayashi.net/appinfo?app=payments-staging',
			back_end: 'https://api.staging.drabayashi.net/payments/info',
			env: 'staging'
		},
		{
			front_end: 'https://api.dev.drabayashi.net/appinfo?app=payments-preprod',
			back_end: 'https://api.beta.zegal.com/payments/info',
			env: 'preprod'
		},
		{
			front_end: 'https://api.dev.drabayashi.net/appinfo?app=payments',
			back_end: 'https://api.zegal.com/payments/info',
			env: 'prod'
		}
	],
	Registration: [
		{
			front_end: 'https://api.dev.drabayashi.net/appinfo?app=register-dev',
			env: 'dev'
		},
		{
			front_end: 'https://api.dev.drabayashi.net/appinfo?app=register-staging',
			env: 'staging'
		},
		{
			front_end: 'https://api.dev.drabayashi.net/appinfo?app=register-preprod',
			env: 'preprod'
		},
		{
			front_end: 'https://api.dev.drabayashi.net/appinfo?app=register',
			env: 'prod'
		}
	]
}
export const DB_STATES = {
	DISCONNECTED: 'disconnected',
	READY: 'ready',
	CONNECTING: 'connecting',
	DISCONNECTING: 'disconnecing'
}
