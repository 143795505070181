export default 	{
	module: 'guides',
	actions: {
		list: true,
		details: true,
		create: true,
		dynamicPaths: [
			{
				paths: {
					'/guides/edit/:id': true,
				},
				keys: {
					':id': 3
				}
			},
			{
				paths: {
					'/guides/:id/details': true
				},
				keys: {
					':id': 2
				}
			},
			{
				paths: {
					'/guides/:id/product-info': true
				},
				keys: {
					':id': 2
				}
			},

		]
	}
}
