import React, {Fragment} from 'react'
import {map} from 'lodash'
import Grid from '@material-ui/core/Grid'
import App from 'app'
import {serverCall} from 'src/submodules/baseApp/common/stores/_general'
import Wrapper from 'src/submodules/components-material/wrappers/wrapper'
import Paper from 'src/submodules/components-material/papers/paper'
import {Header} from 'src/submodules/components-material/typography'
import Lozenge from 'src/submodules/components-material/buttons/lozenge'
import {fromNow} from 'src/submodules/baseApp/common/date'

import statusUrls, {DB_STATES} from './statusUrls'
import {Typography} from '@material-ui/core'

const NO_RESPONSE = 'NO RESPONSE'

const getColorCode = (data, dbStatus) => {
	if (!dbStatus) {
		if (data?.headers?.status === 200) return '#008000'
		else return '#FF0000'
	}
	if (data?.status === 500) {
		if (data?.responseJSON.database) {
			return '#FFA500'
		} else {
			return '#FF0000'
		}
	}
	if (data?.headers?.status === 200 && data?.database === DB_STATES.READY) {
		return '#008000'
	} else if (data?.database === DB_STATES.DISCONNECTED) {
		return '#FFA500'
	} else {
		return '#FF0000'
	}
}

const getInfo = (info) => {
	if (info?.database) {
		return info?.database.toUpperCase()
	} else if (info?.responseJSON) {
		return info?.responseJSON.database.toUpperCase()
	} else {
		return NO_RESPONSE
	}
}

const InfoCard = ({children, data, dbStatus}) => {
	const color = getColorCode(data, dbStatus)
	return (
		<Wrapper display='flex' direction='column' margin='xs'>
			<Lozenge style={{border: color ? `1px solid ${color}` : ''}}>
				<Typography
					variant='caption'
					style={{
						cursor: 'default',
						color: color,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
				>
					{children}
				</Typography>
			</Lozenge>
		</Wrapper>
	)
}

class FetchSomething extends React.Component {
	state = {
		frontEndInfo: null,
		backEndInfo: null,
		workflowInfo: null,
		dlserverInfo: null,
		driveInfo: null,
		authInfo: null,
		loading: true
	}

	componentDidMount() {
		App.stores.menu.page = '/welcome'

		let {front_end, back_end, workflow, dlserver, drive, authorization} = this.props.platform

		let promises = []

		if (front_end) {
			promises.push(serverCall(front_end, {}, 'GET', {withResponseHeader: true}))
		} else {
			promises.push(null)
		}

		if (back_end) {
			promises.push(serverCall(back_end, {}, 'GET', {withResponseHeader: true}))
		} else {
			promises.push(null)
		}

		if (workflow) {
			promises.push(serverCall(workflow, {}, 'GET', {withResponseHeader: true}))
		} else {
			promises.push(null)
		}

		if (dlserver) {
			promises.push(serverCall(dlserver, {}, 'GET', {withResponseHeader: true}))
		} else {
			promises.push(null)
		}

		if (drive) {
			promises.push(serverCall(drive, {}, 'GET', {withResponseHeader: true}))
		} else {
			promises.push(null)
		}

		if (authorization) {
			promises.push(serverCall(authorization, {}, 'GET', {withResponseHeader: true}))
		} else {
			promises.push(null)
		}

		Promise.allSettled(promises).then((response) => {
			const [
				{value: frontEndInfo, reason: frontEndInfoReject},
				{value: backEndInfo, reason: backEndInfoReject},
				{value: workflowInfo, reason: workflowInfoReject},
				{value: dlserverInfo, reason: dlserverInfoReject},
				{value: driveInfo, reason: driveInfoReject},
				{value: authInfo, reason: authInfoReject}
			] = response

			this.setState({
				frontEndInfo: frontEndInfo || frontEndInfoReject,
				backEndInfo: backEndInfo || backEndInfoReject,
				workflowInfo: workflowInfo || workflowInfoReject,
				dlserverInfo: dlserverInfo || dlserverInfoReject,
				driveInfo: driveInfo || driveInfoReject,
				authInfo: authInfo || authInfoReject,
				loading: false
			})
		})
	}

	render() {
		const {loading, frontEndInfo, backEndInfo, workflowInfo, dlserverInfo, driveInfo, authInfo} = this.state
		if (loading) {
			return 'Loading...'
		}

		return (
			<React.Fragment>
				{backEndInfo && (
					<InfoCard data={backEndInfo}>
						API version: {backEndInfo.version}
						<br />
						Deployed {fromNow(backEndInfo.time)} by {backEndInfo.builder}
					</InfoCard>
				)}
				{frontEndInfo && (
					<InfoCard data={frontEndInfo}>
						App version: {frontEndInfo.version}
						<br />
						Deployed {fromNow(frontEndInfo.time)} by {frontEndInfo.builder}
					</InfoCard>
				)}
				{workflowInfo && (
					<InfoCard data={workflowInfo} dbStatus={true}>
						<div>
							<Typography style={{color: getColorCode(workflowInfo, true)}} variant='caption'>
								Service: <b>Workflow </b>
							</Typography>
							Status: {getInfo(workflowInfo)}
						</div>
					</InfoCard>
				)}

				{dlserverInfo && (
					<InfoCard data={dlserverInfo} dbStatus={true}>
						<div>
							<Typography style={{color: getColorCode(dlserverInfo, true)}} variant='caption'>
								Service: <b>DL-Server </b>
							</Typography>
							Status: {getInfo(dlserverInfo)}
						</div>
					</InfoCard>
				)}
				{driveInfo && (
					<InfoCard data={driveInfo} dbStatus={true}>
						<div>
							<Typography style={{color: getColorCode(driveInfo, true)}} variant='caption'>
								Service: <b>Drive </b>
							</Typography>
							Status: {getInfo(driveInfo)}
						</div>
					</InfoCard>
				)}
				{authInfo && (
					<InfoCard data={authInfo} dbStatus={true}>
						<div>
							<Typography style={{color: getColorCode(authInfo, true)}} variant='caption'>
								Service: <b>Authorization </b>
							</Typography>
							Status: {getInfo(authInfo)}
						</div>
					</InfoCard>
				)}
			</React.Fragment>
		)
	}
}

const Status = () => {
	return (
		<Fragment>
			<Grid container spacing={16}>
				{map(statusUrls, (server, name) => (
					<Grid key={name} item xs={12} md={4}>
						<Paper margin='none' display='cover' component={Wrapper}>
							{map(server, (platform) => (
								<Wrapper margin='none' key={`${name}-${platform.env}`}>
									<Header>{`${name} - (${platform.env})`}</Header>

									<FetchSomething platform={platform} />
								</Wrapper>
							))}
						</Paper>
					</Grid>
				))}
			</Grid>
		</Fragment>
	)
}

export default Status
