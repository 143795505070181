import {
	breakpoints,
	scales,
	marginRatio,
	ms
} from '../../global.style.js'

const wrapperStyle = {
	wrapper: {
		// don't delete
	},

	// under
	wrapperUnder: {
		'&header': {
			paddingTop: ms(2.5)
		},

		// wrapper under one fixed header
		'&header1': {
			paddingTop: scales.size.header,

			[breakpoints.up('sm')]: {
				paddingTop: scales.size.headerLargeScreen,
			}
		},

		// wrapper under two fixed header
		'&header2': {
			paddingTop: 'calc(' + scales.size.header + ' * 2)',

			[breakpoints.up('sm')]: {
				paddingTop: 'calc(' + scales.size.headerLargeScreen + ' * 2)',
			}
		},
	},

	// margin ratio
	marginRatio,

	// inner
	wrapperInner: {
		'&flat': {
			width: '100%',
			paddingRight: 16,
			paddingLeft: 16
		},

		'&compress': {
			width: '100%',
			paddingRight: 16,
			paddingLeft: 16,
			paddingBottom: '1.3rem',

			[breakpoints.up('md')]: {
				width: '60%',
				margin: '0 auto'
			}
		},

		'&compress1': {
			width: '100%',
			paddingRight: 16,
			paddingLeft: 16,
			boxSizing: 'border-box',

			[breakpoints.up('md')]: {
				maxWidth: 1280 / 2,
				margin: '0 auto'
			}
		},

		'&compress2': {
			width: '100%',
			paddingRight: 20,
			paddingLeft: 20,
			boxSizing: 'border-box',

			[breakpoints.up('md')]: {
				width: '80%',
				margin: '0 auto'
			}
		},
	},

	// display
	wrapperDisplay: {
		'&flex': {
			display: 'flex'
		},

		'&inline-flex': {
			display: 'inline-flex'
		},

		'&block': {
			display: 'block',
			width: '100%'
		},

		'&cover': {
			display: 'block',
			width: '100%',
			height: '100%'
		},

		// for positioning wrapper above everything
		'&mask': {
			position: 'absolute',
			top: 0,
			bottom: 0,
			right: 0,
			left: 0,
			zIndex: 9999,
		},

		'&fullWidth': {
			width: '100%'
		}
	},

	// position
	wrapperPosition: {
		'&vertical-center': {
			position: 'absolute',
			top: '50%',
			transform: 'translateY(-50%)'
		},

		'&horizontal-center': {
			position: 'absolute',
			left: '50%',
			transform: 'translateX(-50%)'
		},

		'&total-center': {
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)'
		},

		'&relative': {
			position: 'relative'
		},

		'&fixed': {
			position: 'fixed'
		},

		'&absolute': {
			position: 'absolute'
		},
	},

	// flex
	wrapperFlexAuto: {
		'&one': {
			flex: '1 auto'
		},

		'&two': {
			flex: '2'
		}
	},

	// align items
	wrapperFlexAlignItems: {
		'&flex-start': {
			display: 'flex',
			alignItems: 'flex-start'
		},

		'&center': {
			display: 'flex',
			alignItems: 'center'
		},

		'&baseline': {
			display: 'flex',
			alignItems: 'baseline'
		},

		'&flex-end': {
			display: 'flex',
			alignItems: 'flex-end'
		},

		'&stretch': {
			display: 'flex',
			alignItems: 'stretch'
		}
	},

	// align self
	wrapperFlexAlignSelf: {
		'&flex-start': {
			alignSelf: 'flex-start'
		},

		'&center': {
			alignSelf: 'center'
		},

		'&baseline': {
			alignSelf: 'baseline'
		},

		'&flex-end': {
			alignSelf: 'flex-end'
		},
	},

	// justify
	wrapperFlexjustifyContent: {
		'&space-between': {
			display: 'flex',
			justifyContent: 'space-between'
		},

		'&space-around': {
			display: 'flex',
			justifyContent: 'space-around'
		},

		'&flex-start': {
			display: 'flex',
			justifyContent: 'flex-start'
		},

		'&flex-end': {
			display: 'flex',
			justifyContent: 'flex-end'
		},

		'&center': {
			display: 'flex',
			justifyContent: 'center'
		}
	},

	// direction
	wrapperDirection: {
		'&row': {
			display: 'flex',
			flexDirection: 'row'
		},

		'&row-md-up': {
			display: 'flex',
			flexDirection: 'column',

			[breakpoints.up('md')]: {
				flexDirection: 'row'
			}
		},

		'&column': {
			display: 'flex',
			flexDirection: 'column'
		},

		'&column-md-up': {
			display: 'flex',
			flexDirection: 'row',

			[breakpoints.up('md')]: {
				flexDirection: 'column'
			}
		},
	},
}

export default wrapperStyle
