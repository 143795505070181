import isObject from 'lodash/isObject'
import isString from 'lodash/isString'
import get from 'lodash/get'

import {cookieGet} from 'src/submodules/baseApp/common/utils/cookie'

export default (App) => {
	/**
	 * Util method to parse error response message from end point with different error formats
	 */
	App.actions.parseError = (error) => {
		// NOTE: please keep in order

		const case0 = get(error, 'responseJSON.message.message')
		if (case0) {
			// console.log('Case - 0', case0)
			return case0
		}

		const caseA = get(error, 'responseJSON.message')
		if (caseA) {
			// console.log('Case - a', caseA)
			return caseA
		}

		const caseB = get(error, 'responseJSON.error')
		if (caseB) {
			// console.log('Case - b', caseB)
			const caseB1 = get(caseB, 'message') // for responseJSON.error.message

			if (caseB1) {
				return caseB1
			}

			return caseB
		}

		const caseC = get(error, 'error')
		if (caseC && isString(caseC)) {
			// console.log('Case - c', caseC)
			return caseC
		}

		const caseD = get(error, 'error.statusText')
		if (caseD) {
			// console.log('Case - d', caseD)
			return caseD
		}

		const caseE = get(error, 'response.data.message')
		if (caseE) {
			// console.log('Case - e', caseE)
			return caseE
		}

		const caseF = get(error, 'response.data.error')
		if (caseF) {
			// console.log('Case - f')
			return caseF
		}

		const caseG = get(error, 'message')
		if (caseG) {
			// console.log('Case - g')
			return caseG
		}

		const caseH = get(error, 'statusText')
		if (caseH) {
			// console.log('Case - h')
			return caseH
		}

		if (App && App.getConfig('debug')) {
			console.error('Unknown format error:', error)
		}

		return error;
	}

	/**
	 * tests if error message is either string or object and return error message accordingly
	 */
	App.actions.parseErrorMessage = (_string = '', error) => {
		return _string.match(/^["{]/) ?
			App.module('Common.Utils').API.parse(App.actions.parseError(error), error) :
			_string
	}

	/**
	 * Method to show toast error message
	 * @param Error Object
	 * @param log Boolen true to log
	 */
	App.actions.error = (error, log = false, error_name = 'app_error') => {
		if (error.statusText === 'abort') {
			return;
		}

		try {
			error = App.actions.parseErrorMessage(App.actions.parseError(error), error)
			if (error.message) {
				const message = error.message || App.t('app.error.unknown');
				App.actions.message({
					message,
					long: true,
					bad: true
				});
				App.getConfig('debug') && App.actions.warn(error)
				log && App.actions.logError(error_name, {message}, true)
				return message;
			} else {
				const message = error || App.t('app.error.unknown');
				App.actions.message({
					message,
					long: true,
					bad: true
				});
				App.getConfig('debug') && App.actions.warn(error)
				log && App.actions.logError(error_name, {message}, true)
				return message
			}
		} catch (e) {
			App.getConfig('debug') && console.error('Error : ', e);
			return e;
		}
	}

	/**
	 * Log an application error
	 *
	 * @param name string - Error name to log
	 * @param data    object - Error data to be logged data
	 * @param safe    bool   - True to not rediect
	 */
	App.actions.logError = (name, data, safe) => {
		// console.important('-------ERROR CAUGHT--------');
		// console.log('name', name);
		// console.log('data', data);
		// console.log('safe', safe)

		if (!name && !data) {
			console.error('Bad error called, no name or data')
			return
		}

		if (isString(data)) {
			data = {message: data}
		}

		let hasSavedLog = false
		let okToLog = true

		const responses = {
			0: () => {
				// normally this means there is no internet connection
				// thus do not enter into a error logging loop
				okToLog = false;
			},
			400: () => {
				// Something not found, eg: document, activity
				safe = true
			},
			401: () => {
				// console.log(name, data, safe);
				// okToLog = false;
				// if there is a specific name
				// and this is a perms issue, then display the invalid
				if (data && data.responseJSON && data.responseJSON.error) {
					console.error('Permission error');
				} else {
					// token is invaild, so clear token
					cookieGet(App.getHeader('AuthDomain').key, {})
					// do not display the auth error:
					safe = true
				}
			},
			403: () => {
				console.error('403 Forbidden');
			},
			404: () => {
				console.error('404 found');
			},
			502: () => {
				// Bad Gateway
				safe = true
			},
			800: () => {
				// Not entitled
				console.error('You do not have sufficient credits');
			},
			900: () => {
				// User validation error
			},
			920: () => {
				// Unknown client error of some kind
			},
			921: () => {
				// Uncaught Javascript client error (auto)
			},
			922: () => {
				// Javascript client error, caught by error boundry
			},
			999: (data) => {
				// uncaught api error
				// App.log('app_error', data)
				App.event('error', data, false)
				hasSavedLog = true
			},
		};

		if (isObject(name)) {
			data = name;
			name = 'unknown_error';
		}

		let status = data ? data.status : 'Unknown'
		let handler = responses[status];

		// console.warn('Handler status:', okToLog, status, {data})

		if (handler === undefined) {
			// Eg: API 500
			const finalData = {
				name,
				...data,
			}

			if (!status) {
				finalData.message2 = 'Missing status in error'
			} else {
				finalData.status = status
			}

			responses['999'](finalData)
		} else {
			handler(data)
		}

		if (data && data.status === undefined) {
			// console.log('0');
			okToLog = false
		}

		if (data && data.user) {
			data.user = App.stores.user.email
		}

		// console.error('Error: ' + (name || 'Unknown error'), data);

		if (name === 'error') {
			// console.log('1');
			okToLog = false
		}

		if (!hasSavedLog) {
			// write to the logs
			App.log(name, data, App.getConfig('platform'), 1);

		}

		if (data && data.url && data.url.includes && data.url.includes('/events')) {
			okToLog = false
		}

		// ok to log means it was not a network error
		// because if we have that, trying another network request is bad news. (as it will cause cyclic errors)
		if (okToLog) {
			// and force a push of the logs to the server
			App.logger.send()
		}

		if (name === 'Cannot connect to server') {
			// no internet connection pop up?
		}

		App.lastRoute = App.getCurrentRoute();

		// if the error is not a safe one, we need to redirect the app
		if (safe !== true) {
			const ErrorComponent = App.loadFile({loader: () => import('src/submodules/components-material/error')})
			const nicename = (isString(data) ? data : data.message) || name
			App.actions.loadPage(ErrorComponent, {name: nicename})
		}
	}
	/**
	 * Almost like App.actions.error,
	 * Use this method if no need to parse error object from string returned
	 *
	 * @param error Object - actual error object
	 * @param notify Boolen - true if need to show toast message
	 * @param options Object stopThowing = true if u want to throw error in return, false for scilent error processing.
	 */

	App.actions.processError = (error, notify, options = {}) => {
		// console.log('error', error);
		let message = App.actions.parseError(error)

		notify && App.actions.message({message, bad: true})

		if (options.log) {
			let status = 400
			let statusText = null

			if (error.status) {
				statusText = error.statusText
			}

			if (error.statusText) {
				statusText = error.statusText
			}

			App.log('app_error', {message, status, statusText})
		}

		if (options.stopThowing) {
			return
		}

		// so that if called from a promise, error can be caught
		throw error
	}

	return App;
}

