import React from 'react'

// Notification component is extended from './action'
import Button from './button'

/*
* Usage example
*
* <Lozenge
*	color='primary | danger'
* >
* 	'string'
* </Lozenge>
*
*/

function Lozenge(props) {
	const {
		children,
		value,
		...rest
	} = props

	const lozengeProps = {
		size: 'xs',
		border: true,
		disableRipple: true,
		style: {
			boxShadow: 'none'
		},
		...rest
	}

	return (
		<Button {...lozengeProps}>
			{children || value}
		</Button>
	)
}

export default Lozenge
