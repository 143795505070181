import {
	omit,
	isBoolean,
} from 'lodash'
import React from 'react'
import {Link} from 'react-router-dom'
// nodejs library for concatinating classes
import classNames from 'classnames'
import PropTypes from 'prop-types'

// material-ui components
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';

import IconWrapper from '../wrappers/icon'
import App from 'app'

// component style
import buttonStyle from '../assets/styles/button/base'

/*
* Usage example
*
* <Button
*	onClick={this.handleClick} (If link than use props 'to')
*	color='primary | danger | warning | white | black | transparent | false'
*	size='xs | sm | lg | auto | false'
*	active='bool'
*	disabled='bool'
*	border='bool'		(gives button border)
*	link='bool' 		(gives button style of same as anchor tag)
*	opacity='bool' 		(gives button opacity color)
*	simple='bool' 		(button with no background, color applies only on text)
*	withIcon='bool'		(if button have icon + text)
*	justIcon='bool'		(if button have only icon | can be fab button too)
*	className='string'
* >
*	string
* </Button>
*
*/

const proxyClickAndLog = (id, handleClick) => (...args) => {
	App.log('click_event', {
		data: {
			button: id,
		}
	})

	return handleClick(...args)
}

function RegularButton({...props}) {
	const {
		classes,
		color,
		active,
		upperCase,
		disabled,
		size,
		border,
		link,
		opacity,
		simple,
		icon,
		withIcon,
		justIcon,
		className,
		to,
		handleClick,
		children,
		isTextButtton,
		...rest
	} = props

	// console.warn('props', props)

	let filteredProps = rest

	const btnClasses = classNames({
		[classes.button]: true,
		[classes.disabled]: disabled,
		[classes.active]: active,
		[classes.upperCase]: upperCase,
		[classes[color]]: !disabled && color,
		[classes[size]]: size,
		[classes.border]: border,
		[classes.link]: link,
		[classes.opacity]: opacity,
		[classes.simple]: simple,
		[classes.withIcon]: withIcon || (icon && children),
		[classes.justIcon]: justIcon || (icon && !children),
		[className]: className,
		[classes.textButton]: isTextButtton
	})

	// need to remove it if it is boolean, so we dont get prop type errors
	if (isBoolean(filteredProps.onClick)) {
		filteredProps = omit(filteredProps, 'onClick')
	}

	const buttonProps = {
		...filteredProps,
		className: btnClasses,
		classes: {
			label:
				`${justIcon ? 'material-icons' : 'button-label'}`
		}
	}

	if (to) {
		buttonProps.component = Link
		buttonProps.to = to
	}

	if (handleClick || buttonProps.onClick) {
		buttonProps.onClick = proxyClickAndLog(buttonProps.id || buttonProps['data-zegal-component'], handleClick || buttonProps.onClick)
	}

	return (
		<Button {...buttonProps}>
			{icon &&
				<IconWrapper color='inherit'>
					{icon}
				</IconWrapper>
			}

			{children}
		</Button>
	)
}

RegularButton.propTypes = {
	classes: PropTypes.object.isRequired,
	disabled: PropTypes.bool,
	active: PropTypes.bool,
	upperCase: PropTypes.bool,
	color: PropTypes.oneOf([
		'primary',
		'danger',
		'warning',
		'white',
		'black',
		'transparent',
		false
	]),
	size: PropTypes.oneOf([
		'xs',
		'sm',
		'lg',
		'auto',
		false
	]),
	border: PropTypes.bool,
	link: PropTypes.bool,
	opacity: PropTypes.bool,
	simple: PropTypes.bool,
	icon: PropTypes.any,
	withIcon: PropTypes.bool,
	justIcon: PropTypes.bool,
	className: PropTypes.string,
	to: PropTypes.any, // could be bool
	handleClick: PropTypes.any, // could be bool
	isTextButtton: PropTypes.bool // set variant='text'
}

export default withStyles(buttonStyle)(RegularButton)
