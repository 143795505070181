import React from 'react'
import propTypes from 'prop-types'

import App from 'app'

import Typography from '@material-ui/core/Typography'

import {DeadDragon} from './icons'
import Button from './buttons/button'
import Wrapper from './wrappers/wrapper'
import Spacing from './lab/wrapper'
import Paper from './papers/paper'

const redirectToHome = () => window.location = App.getConfig('homepath')

/*
 Base component for using common
 layout for error component
*/

export const ErrorBaseLayout = ({
	title,
	message,
	renderAction,

	// for styling
	wrapperProps,
	contentWrapperProps
}) =>
	<Wrapper {...wrapperProps}>
		<Spacing m={2} pr={2}>
			<DeadDragon />
		</Spacing>

		<Wrapper direction='column' {...contentWrapperProps} m={-1} component={Spacing}>
			<Typography variant='h5' gutterBottom>
				{title}
			</Typography>

			<Typography variant='subtitle1' gutterBottom>
				{message}
			</Typography>

			{renderAction && renderAction()}
		</Wrapper>
	</Wrapper>

ErrorBaseLayout.propTypes = {
	title: propTypes.string,
	message: propTypes.string,
	renderAction: propTypes.func
}

export const SorryError = ({
	title,
	message,
	onClick = redirectToHome
}) =>
	<ErrorBaseLayout
		title={title || `Something has gone wrong!`}
		message={message || `We're sorry, it goes without saying that this wasn't meant to happen.`}
		renderAction={() =>
			<Typography component='span' variant='body2'>
				<Wrapper alignItems='center'>
					Click &nbsp;
					<Button
						id='sorry-action-recover'
						color='primary'
						link
						onClick={onClick}
					>
						<Typography color='inherit'>here</Typography>
					</Button>
					&nbsp; to return to the main page.
				</Wrapper>
			</Typography>
		}

		// styling props
		wrapperProps={{
			alignItems: 'center',
			direction: 'row-md-up',
			inner: 'compress1',
			style: {
				marginTop: '2.6rem'
			},
			component: Paper
		}}
		contentWrapperProps={{
			alignItems: 'flex-start'
		}}
	/>

export const TryAgainError = ({
	title,
	message,
	onClick = redirectToHome
}) =>
	<ErrorBaseLayout
		title={title || `Oops! This is embarrassing...`}
		message={message || `Something went wrong on our end.`}
		renderAction={() =>
			<Button
				id='try-again-action-recover'
				color='primary'
				onClick={onClick}
			>
				Please try again
			</Button>
		}

		// styling props
		wrapperProps={{
			direction: 'column',
			alignItems: 'center',
			mt: 1,
			mb: 4,
			component: Spacing
		}}
		contentWrapperProps={{
			direction: 'column',
			alignItems: 'center'
		}}
	/>

export default SorryError
