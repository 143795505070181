/*
 *  Note:  This file should only consist icons which is tends
 *		  to be distributed multiple times in our different app.
 *
 *		  First you need to place your icon inside images/common
 */

import React from 'react'

import Logo from './assets/svgs/icon-dragonlaw.svg'
import LogoWithText from './assets/svgs/icon-logo-text.svg'
import LogoWithTextRuby from './assets/svgs/icon-logo-text--ruby.svg'
import DeadDragon from './assets/svgs/icon-error--mono.svg'

import AmericanExpress from './assets/svgs/icon-cc-amex--color.svg'
import MasterCard from './assets/svgs/icon-cc-mastercard--color.svg'
import Visa from './assets/svgs/icon-cc-visa--color.svg'

import NotFound from './assets/svgs/not-found.svg'
import Alert from './assets/svgs/icon-alert--color.svg'

import MailExpired from './assets/svgs/icon-mail--expired.svg'
import MailConfirmed from './assets/svgs/icon-mail--confirmed.svg'
import MailColor from './assets/svgs/icon-mail--color.svg'

import GoogleG from './assets/svgs/icon-googleg.svg'
import MicrosoftG from './assets/svgs/icon-microsoftg.svg'
import FreeDoc from './assets/svgs/icon-free-doc.svg'
import Scales from './assets/svgs/icon-scales.svg'

import ZegalSign from './assets/svgs/icon-zegal-sign--teal.svg'

import IconWrapper from './wrappers/icon'

export const Icon = (name, Component) => (
	<IconWrapper color='inherit'>
		{name}
		{Component}
	</IconWrapper>
)

const document = Icon('description')
const event = Icon('event')
const upload = Icon('', <ZegalSign />)
const decision = Icon('thumbs_up_down')
const checklist = Icon('playlist_add_check')

export {
	Logo,
	LogoWithText,
	LogoWithTextRuby,
	DeadDragon,
	AmericanExpress,
	MasterCard,
	Visa,
	NotFound,
	Alert,
	MailExpired,
	MailConfirmed,
	MailColor,
	GoogleG,
	MicrosoftG,
	FreeDoc,
	Scales,
	ZegalSign,
	document,
	event,
	upload,
	decision,
	checklist
}
