export default {
	module: 'doctypes',
	actions: {
		list: true,
		create: true,
		dynamicPaths: [
			{
				paths: {
					'/doctypes/edit/:id': true,
				},
				keys: {
					':id': 3
				}
			}
		]
	}
}

