import {
	extend,
} from 'lodash'

import App from 'submodules/baseApp/base'
import Modal from 'submodules/components-material/modals/modal'

/**
 * When triggered displays an overview modal - ReactJS Components only
 *
 * @param {Function} component - React Component to display
 * @param {Object} props - Options
 * @param {Function} callback - Callback to init when modal is closed
 */
export const modalReact = (component, props, callback) => {
	return App.actions.loadComponent(Modal, extend({
		component,
		callback
	}, {
		childProps: props,
		provide: ['general']
	}))
}
