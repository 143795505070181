export default {
	module: 'activityCategories',
	actions: {
		list: true,
		create: true,
		dynamicPaths: [
			{
				paths: {
					'/activityCategories/edit/:id': true,
				},
				keys: {
					':id': 3
				}
			},

			{
				paths: {
					'/activityCategories/edit/:id/manageActivities': true,
				},
				keys: {
					':id': 3
				}
			},

			{
				paths: {
					'/activityCategories/edit/:id/manageOrgs': true,
				},
				keys: {
					':id': 3
				}
			},

			{
				paths: {
					'/activityCategories/edit/:id/managePlans': true,
				},
				keys: {
					':id': 3
				}
			},
		]
	}
}
