export default {
	module: 'workflow-templates',
	actions: {
		new: true,
		clone: true,
		list: true,
		edit: true,
		details: true,
		create: true,
		dynamicPaths: [
			{
				paths: {
					'/workflow-templates/clone-template/:id': true,
				},
				keys: {
					':id': 3,
				},
			},
			{
				paths: {
					'/workflow-templates/template/:id': true,
				},
				keys: {
					':id': 3,
				},
			},
		],
	},
};
