import activityCategoriesPermissions from './permissions/activityCategories';
import couponPermissions from './permissions/coupons';
import credits from './permissions/credits';
import docModification from './permissions/docModification';
import doctypes from './permissions/doctypes';
import guideCategoriesPermissions from './permissions/guideCategories';
import guidePermissions from './permissions/guides';
import intercomAdmin from './permissions/intercomAdmin';
import landingPages from './permissions/landingPages';
import products from './permissions/products';
import linkBuilderPermissions from './permissions/linkBuilder';
import notificationsPermissions from './permissions/notifications';
import orgPermissions from './permissions/orgs';
import partnersPermissions from './permissions/partners';
import planPermissions from './permissions/plans';
import scribesPermissions from './permissions/scribes';
import userPermissions from './permissions/users';
import unauthedPermissions from './permissions/unauthed';
import labelsPermissions from './permissions/labels';
import whitelabelPermissions from './permissions/whitelabel';
import settingsPermissions from './permissions/settings';
import workflowTemplatesPermissions from './permissions/workflowTemplates';
const roleMap = [
	{
		role: 'DEFAULT', // Default permissions for un-authed user:
		name: 'Un-authed user',
		activities: [
			{
				module: 'auth',
				actions: {
					main: {
						googleAuth: false,
						normalAuth: true,
					},
				},
			},
		],
		routes: [
			{
				module: 'auth',
				actions: {
					registration: {
						fastRegistration: true,
					},
				},
			},
			...unauthedPermissions,
		],
	},
	{
		role: 'SYSTEM_ADMIN',
		name: 'Admin',
		routes: [
			{...userPermissions},
			{...couponPermissions},
			{...planPermissions},
			{...orgPermissions},
			{...whitelabelPermissions},
			{...notificationsPermissions},
			{...partnersPermissions},
			{...guidePermissions},
			{...guideCategoriesPermissions},
			{...scribesPermissions},
			{...linkBuilderPermissions},
			{...activityCategoriesPermissions},
			{...landingPages},
			{...intercomAdmin},
			{...doctypes},
			{...docModification},
			{...labelsPermissions},
			{...products},
			{...settingsPermissions},
			{...workflowTemplatesPermissions},
		],
		activities: [
			{
				module: 'user',
				actions: {
					edit: {
						changePassword: true,
						changeRoles: true,
						unauth: true,
					},
				},
			},
		],
	},
	{
		role: 'USER',
		name: 'User',
		routes: [
			{
				module: 'auth',
				actions: {},
			},
		],
	},
	{
		role: 'SCRIBE_DEVELOPER',
		name: 'SCRIBE DEVELOPER',
		routes: [{...scribesPermissions}],
	},
	{
		role: 'SCRIBE_MANAGER',
		name: 'SCRIBE MANAGER',
		routes: [{...scribesPermissions}],
	},
	{
		role: 'TESTING_USER',
		name: 'TESTING_USER',
		routes: [credits, doctypes],
	},
	{
		role: 'OPERATIONS_SUPPORT',
		name: 'Operations Support',
		routes: [
			{...userPermissions},
			{...orgPermissions},
			{...whitelabelPermissions},
			{...partnersPermissions},
			{...scribesPermissions},
			{...linkBuilderPermissions},
		],
	},
	{
		role: 'PARTNER_MANAGER',
		name: 'Partner Manager',
		routes: [{...partnersPermissions}],
	},
	{
		role: 'SALES_MANAGER',
		name: 'Sales Manager',
		routes: [{...couponPermissions}, {...linkBuilderPermissions}],
	},
	{
		role: 'SALES_AGENT',
		name: 'Sales Agent',
		routes: [{...linkBuilderPermissions}],
	},
	{
		role: 'SERVICE_MANAGER',
		name: 'Service Manager',
		routes: [
			{...couponPermissions},
			{...orgPermissions},
			{...whitelabelPermissions},
			{...userPermissions},
			{...linkBuilderPermissions},
		],
	},
	{
		role: 'SERVICE_PROVIDER',
		name: 'Service Provider',
		routes: [],
	},
	{
		role: 'CONTENT_MANAGER',
		name: 'Content Manager',
		routes: [
			{...guidePermissions},
			{...guideCategoriesPermissions},
			{...scribesPermissions},
			{...activityCategoriesPermissions},
			{...landingPages},
			{...labelsPermissions},
			{...settingsPermissions},
			{...workflowTemplatesPermissions},
		],
	},
	{
		role: 'CONTENT_DEVELOPER',
		name: 'Content Developer',
		routes: [
			{...guidePermissions},
			{...scribesPermissions},
			{...linkBuilderPermissions},
			{...labelsPermissions},
			{...workflowTemplatesPermissions},
		],
	},
	{
		role: 'NOTIFICATION_MANAGER',
		name: 'Notification Manager',
		routes: [{...notificationsPermissions}],
	},
	{
		role: 'PLAN_MANAGER',
		name: 'Plan Manager',
		routes: [
			{...planPermissions},
			{...landingPages},
			{...intercomAdmin},
		],
	},
];

export default roleMap;
