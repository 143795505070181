import {
	breakpoints,
	primaryColor,
	primaryLightColor,
	dangerColor,
	warningColor,
	secondaryColor,
} from '../../global.style.js'

const wrapperStyle = {
	icon: {
		color: secondaryColor,
		fontSize: 24,
		margin: '0 6px',

		'& svg': {
			width: 24,
			height: 24,
		}
	},

	isHover: {
		color: secondaryColor,
		cursor: 'pointer',

		'&:focus, &:hover, &:visited': {
			color: '#aaa',
			opacity: .8
		},
	},

	iconColor: {
		'&inherit': {
			'&, &:focus, &:hover, &:visited': {
				color: 'inherit',
			}
		},

		'&primary': {
			'&, &:focus, &:hover, &:visited': {
				color: primaryColor,
			}
		},

		'&primaryLight': {
			'&, &:focus, &:hover, &:visited': {
				color: primaryLightColor,
			}
		},

		// default
		'&white': {
			'&, &:focus, &:hover, &:visited': {
				color: '#fff',
			}
		},

		'&danger': {
			'&, &:focus, &:hover, &:visited': {
				color: dangerColor,
			}
		},

		'&warning': {
			'&, &:focus, &:hover, &:visited': {
				color: warningColor,
			}
		},
	},

	iconSize: {
		'&xs': {
			fontSize: 18,

			'& svg': {
				width: 20,
				height: 20,
			}
		},

		'&sm': {
			fontSize: 20,

			'& svg': {
				width: 22,
				height: 22,
			}
		},

		'&md': {
			fontSize: 38,

			'& svg': {
				width: 40,
				height: 40,
			},

			'& .material-icons': {
				fontSize: 38,
			}
		},

		'&lg': {
			fontSize: 52,

			'& svg': {
				width: 54,
				height: 54,
			}
		},

		'&xl': {
			fontSize: 66,

			'& svg': {
				width: 68,
				height: 68,
			}
		},

		'&hero': {
			// it is only for svg
			width: '100%',

			'& svg': {
				width: '80%',
				height: 200,
				margin: '10px auto',

				[breakpoints.up('lg')]: {
					width: '40%',
					height: 300,
					margin: '20px auto',
				}
			}
		},
	},

	marginRatio: {
		'&sm': {
			margin: '0 2px',
		},

		'&lg': {
			margin: '0 10px',
		},

		'&none': {
			margin: 0
		}
	}
}

export default wrapperStyle
