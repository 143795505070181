import {map, has, isObject} from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'mobx-react';
import {unregister} from './serviceWorker';

import App from 'app';
import BaseWrapper from './base';
import config from './common/config/app';
import Menu from 'submodules/baseApp/menu/components/wrapper';

import './sprites';
import './styles/dragonlaw-admin.scss';
import PkgInfo from '../package.json';
import {BrowserRouter as Router} from 'react-router-dom';

import {WrapThemeProvider} from 'common/components/wrapThemeProvider';

import {Loading} from 'common_components';

import Dashboard from './modules/dashboard';

// import 'submodules/baseApp/auth/entities/i18n'
import 'submodules/baseApp/common/utils';
// eslint-disable-next-line
console.log('Starting app:', PkgInfo.version, App);

App.BaseWrapper = BaseWrapper;
App.defaults = {
	version: PkgInfo.version,

	// since we don't use orgs in this project, we need to disable them
	fakeOrg: true,

	// make sync use our special headers for auth
	updateBackboneSync: true,

	// the route to goto after login
	homeroute: 'home:land',
	homepath: '/welcome',
};

App.modalRegion = {};

App.adminModules = [
	import('./modules/activityCategories/app.js'),
	import('./modules/contentRepo/app.js'),
	import('./modules/coupon/app.js'),
	import('./modules/docModification/app.js'),
	import('./modules/doctypes/app.js'),
	import('./modules/guideCategories/app.js'),
	import('./modules/guides/app.js'),
	import('./modules/intercomAdmin/app.js'),
	import('./modules/landingPages/app.js'),
	import('./modules/linkBuilder/app.js'),
	import('./modules/notifications/app.js'),
	import('./modules/orgs/app.js'),
	import('./modules/partners/app.js'),
	import('./modules/plans/app.js'),
	import('./modules/scribes/app.js'),
	import('./modules/users/app.js'),
	import('./modules/credits/app.js'),
	import('./modules/labels/app.js'),
	import('./modules/products/app.js'),
	import('./modules/whitelabelOrgs/app.js'),
	import('./modules/settings/app.js'),
	import('./modules/workflowTemplates/app.js'),
	import('./modules/companyType/app.js')
];

App.authModules = [];
App.userModules = [];

App.loadCustomMenus = () => {
	// NOTE: Side bar needs to load first
	// because other pages need to select things on it.
	// the rest of the starting is fired when this completes loading
	return import('submodules/baseApp/menu/app')
		.then(() => import('submodules/baseApp/menu/components/left'))
		.then((LeftMenu) => {
			App.stores.menu.addMenu('left', LeftMenu.default);
			console.log('Menu loaded:left');
		});
};

App.processes.afterStart = () => {};

App.processes.afterSetup = () => {
	App.homeroute = '/welcome';
	App.settings.APIDTEPrefix = '/dte/';
	App.settings.title = 'Dragon Law Admin';

	if (App.settings.debug) {
		window.app = App;
	}

	// must be added after lodash is ready
};

App.processes.afterLogout = () => {
	App.setNextUrl(App.getConfig('homepath'));
};

class AppWrapper extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			authRoutes: false,
			userRoutes: false,
			couponRoutes: false,
			planRoutes: false,
		};
	}

	componentDidMount() {
		App.radio.on('setup:admin:modules:loaded', () => {
			App.radio.trigger('stylesheet:loaded:styles');

			import('./modules/integrations/app').then((Integrations) => {
				Integrations.default.load();
			});

			App.actions.setupStores().then(() => {
				const routes = App.Auth.routes;
				App.stores.markets.fetch();

				// For admin app the mobile is always true
				// We are not using modernizer here
				App.stores.menu.setMobile(true);

				routes.push({
					path: '/welcome',
					component: () => <Dashboard />,
				});

				this.setState({
					authRoutes: routes,
					loading: false,
				});
			});
		});
	}

	render() {
		const {loading, authRoutes} = this.state;

		return (
			<div>
				{loading ? (
					<Loading />
				) : (
					<Provider {...App.stores}>
						<Router {...App.stores}>
							<App.BaseWrapper
								authRoutes={map(authRoutes, 'path')}
							>
								<div id='app'>
									<WrapThemeProvider>
										<div
											id='app-menu'
											className='sidebar-left'
										>
											<Menu />
										</div>

										<div
											id='app-content'
											className='content-right'
										>
											{authRoutes &&
												authRoutes.map((route, i) => (
													<App.RouteBuilder
														key={'auth-route-' + i}
														{...route}
													/>
												))}

											{map(App.modules, (module) => {
												if (
													isObject(module) &&
													has(module, 'getRoutes')
												) {
													return map(
														module.getRoutes,
														(route, i) => {
															return (
																<App.RouteBuilder
																	name={`${module.channelName}-${i}`}
																	key={
																		module.channelName +
																		'route-' +
																		i
																	}
																	{...route}
																/>
															);
														}
													);
												}

												if (
													isObject(module) &&
													has(module, 'routeList') &&
													module.routeList
												) {
													return map(
														module.routeList,
														(route, i) => {
															return (
																<App.RouteBuilder
																	key={
																		module.channelName +
																		'route-' +
																		i
																	}
																	{...route}
																/>
															);
														}
													);
												}
											})}
										</div>
									</WrapThemeProvider>
								</div>
							</App.BaseWrapper>
						</Router>
					</Provider>
				)}
			</div>
		);
	}
}

function renderApp() {
	ReactDOM.render(
		React.createElement(AppWrapper, {stores: App.Stores}),
		document.getElementById('glb-content')
	);
}

App.start({config}, {renderApp});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.register(undefined, App.stores.general);
// register(undefined, App.stores.general)
unregister();
