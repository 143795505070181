import React from 'react'
import PropTypes from 'prop-types'

import CircularProgress from '@material-ui/core/CircularProgress'

import Wrapper from '../wrappers/wrapper'

function Circular(props) {
	const {size, color, center, totalCenter, className} = props;

	let circumference = 30

	if (size === 'lg') {
		circumference = 40
	} else if (size === 'sm') {
		circumference = 16
	}

	return (
		<Wrapper
			data-zegal-spinner='__ZEGAL__LOADING__SPINNER__'
			display='flex' position={totalCenter ? 'total-center' : 'relative'}
			justify={center ? 'center' : false}
		>
			<CircularProgress
				className={`${className}`}
				color={color || 'primary'}
				size={circumference}
			/>
		</Wrapper>
	);
}

Circular.propTypes = {
	size: PropTypes.oneOf([
		'sm',
		'lg'
	]),
	center: PropTypes.bool,
	totalCenter: PropTypes.bool,
	className: PropTypes.string,
};

export default Circular
