import {values} from 'mobx'
import {getParent, getRoot, applySnapshot} from 'mobx-state-tree'

import App from 'app'

export const setKey = (self) => (key, value) => {
	self[key] = value
	return self[key]
}

export const serverCall = (url, data, method = 'POST', options = {}) => {
	let server

	if (App.getConfig('ajaxMode') === 'Axios') {
		server = import('./serverCallAxios')
		// server = import(`./serverCall${App.getConfig('ajaxMode')}`)

	} else {
		server = import('submodules/baseApp/common/stores/serverCallBackbone')
	}

	return server.then((imported) => {
		// console.log('method', method);
		// console.log('imported', imported);
		if (!imported.default[method]) {
			console.error('MISSING AJAX METHOD:', method)
			return Promise.reject()
		}

		return imported.default[method](url, data, options)
	})
}

export default (self) => {
	return {
		setKey: setKey(self),
		serverCall,
		_POST(endpoint, data) {
			return self.serverCall(`${self.url}/${self._id}/${endpoint}`, data, 'POST')
		},

		// if you want to override this in your model, you can
		// NOTE: this is called after a FETCH on the parent collection, when adding this item.
		update(data) {
			self._update(data)
		},

		_update(data) {
			applySnapshot(self, data)
		},
	}
}

export const generalViews = (self) => {
	return {
		getValues(source) {
			if (source) {
				return values(source)
			}

			if (self.models) {
				return values(self.models)
			}

			console.error('Cannot find what you want!')
			return []
		},

		get root() {
			return getRoot(self)
		},

		get parent() {
			return getParent(self)
		},

		log(...args) {
			self.debug && console.log(...args)
		}
	}
}
