import React from 'react'

import {MuiThemeProvider} from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

import theme from '../../theme'
import '../../sprites'
import '../../styles/dragonlaw-admin.scss';

export const WrapThemeProvider = ({children}) =>
	<MuiThemeProvider theme={theme}>
		<CssBaseline>
			{children}
		</CssBaseline>
	</MuiThemeProvider>
