import {
	secondaryColor,
	secondaryDarkColor,
	secondaryLightColor,
	primaryColor,
	primaryLightColor,
	dangerColor,
	dangerLightColor,
	warningColor,
	warningLightColor,
	dirtGreyColor,
	blackColor,
	whiteColor,
	primaryBackground,
	dangerBackground,
	warningBackground,
	font,
	pxToRem,
	breakpoints
} from '../../global.style.js'

const buttonStyle = {
	button: {
		minHeight: 'auto',
		minWidth: 'auto',
		backgroundColor: secondaryDarkColor,
		color: whiteColor,
		boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, .3)',
		border: 'none',
		borderRadius: 3,
		position: 'relative',
		padding: `${pxToRem(10)} ${pxToRem(20)}`,
		margin: `${pxToRem(5)} ${pxToRem(1)}`,
		fontSize: font.regular,
		fontWeight: 400,
		textTransform: 'none',
		letterSpacing: 0,
		willChange: 'box-shadow, transform',
		transition:
			'box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
		lineHeight: '1.3',
		textAlign: 'center',

		'&:hover,&:focus': {
			color: whiteColor,
			backgroundColor: secondaryColor,
		},

		// transitioning label
		'& label, & .button-label': {
			cursor: 'inherit',
			transition: 'all .3s ease-in-out',

			'& > *:last-child': {
				alignSelf: 'baseline'
			}
		},

		// if button has spinner
		'&.has-spinner': {
			// spinner wrapper
			'& *[data-zegal-spinner="__ZEGAL__LOADING__SPINNER__"]': {
				'& > *': {
					margin: 0
				}
			},

			// spinner button label
			'& label': {
				transform: 'translateX(8px)',
			}
		},
	},

	textButton: {
		padding: '0 !important',
		margin: '0 !important',
	},
	// state
	disabled: {
		boxShadow: 'none',
		opacity: '0.65',
		pointerEvents: 'none',
	},

	active: {
		...primaryBackground,
	},

	upperCase: {
		textTransform: 'uppercase',

		'& *': {
			textTransform: 'inherit',
		}
	},

	// color
	primary: {
		...primaryBackground
	},

	danger: {
		...dangerBackground
	},

	warning: {
		...warningBackground
	},

	white: {
		'&, &:focus, &:hover, &:visited': {
			backgroundColor: whiteColor,
			color: primaryColor,
		}
	},

	black: {
		'&, &:focus, &:hover, &:visited': {
			backgroundColor: secondaryDarkColor,
			color: whiteColor,
		}
	},

	transparent: {
		'&, &:focus, &:hover': {
			background: 'transparent',
			boxShadow: 'none',
			color: 'inherit'
		}
	},

	// size
	lg: {
		padding: `${pxToRem(10)} ${pxToRem(20)}`,
		borderRadius: 3,
	},

	sm: {
		margin: `${pxToRem(1)}`,
		padding: `${pxToRem(5)} ${pxToRem(10)}`,
		fontSize: font.small,
		borderRadius: 3
	},

	xs: {
		margin: `${pxToRem(0)} ${pxToRem(1)}`,
		padding: `${pxToRem(5)} ${pxToRem(10)}`,
		fontSize: '0.675rem',
		borderRadius: 2,
		fontWieight: 600
	},

	auto: {
		height: 'auto',
		width: 'auto',
		padding: `${pxToRem(2)} ${pxToRem(4)}`,
	},

	// border
	border: {
		'&, &:focus, &:hover, &:visited': {
			backgroundColor: whiteColor,
			color: secondaryColor,
			border:
				'1px solid' + secondaryColor,
			/*
			* Adding small vertical offset shadow
			* for to overcome cross-browser cutting
			* border line issue.
			*/
			boxShadow: 'none',
		},

		'&$disabled': {
			'&, &:focus, &:hover, &:visited': {
				border: '1px solid ' + secondaryColor,
				color: secondaryColor + ' !important'
			}
		},

		'&$active': {
			...primaryBackground,
			color: whiteColor
		},

		'&$primary': {
			'&, &:focus, &:hover, &:visited': {
				border: '1px solid ' + primaryColor,
				color: primaryColor,
			},

			'&:hover': {
				backgroundColor: primaryLightColor
			}
		},

		'&$danger': {
			'&, &:focus, &:hover, &:visited': {
				border: '1px solid ' + dangerColor + ' !important',
				color: dangerColor,
			},

			'&:hover': {
				backgroundColor: dangerLightColor
			}
		},

		'&$warning': {
			'&, &:focus, &:hover, &:visited': {
				border: '1px solid ' + warningColor + ' !important',
				color: dirtGreyColor
			},

			'&:hover': {
				backgroundColor: warningLightColor
			}
		},

		'&$black': {
			'&, &:focus, &:hover, &:visited': {
				border: '1px solid ' + secondaryDarkColor + ' !important',
				color: dirtGreyColor,
			},

			'&:hover': {
				backgroundColor: secondaryLightColor
			}
		},
	},

	// link
	link: {
		'&, &:focus, &:hover, &:visited': {
			padding: 0,
			backgroundColor: 'transparent',
			boxShadow: 'none',
			display: 'inline-flex',
			color: 'inherit'
		},

		'& .material-icons': {
			marginRight: 6
		},

		'&$disabled': {
			'&, &:focus, &:hover, &:visited': {
				color: secondaryColor + ' !important'
			}
		},

		'&$primary': {
			'&, &:focus, &:hover, &:visited': {
				color: primaryColor,
			}
		},

		'&$danger': {
			'&, &:focus, &:hover, &:visited': {
				color: dangerColor,
			}
		},

		'&$warning': {
			'&, &:focus, &:hover, &:visited': {
				color: warningColor,
			}
		},

		'&$black': {
			'&, &:focus, &:hover, &:visited': {
				color: blackColor,
			}
		},
	},

	withIcon: {
		display: 'inline-flex',

		'& .material-icons': {
			margin: '0 6px 0 0'
		},

		'&$xs': {
			'& .material-icons': {
				fontSize: 16,
			}
		},

		'&$sm': {
			'& .material-icons': {
				fontSize: 20,
			}
		},

		'&$lg': {
			'& .material-icons': {
				fontSize: 52
			}
		},

		'&$auto': {
			height: 'auto',
			width: 'auto',
		}
	},

	justIcon: {
		height: 32,
		width: 32,
		borderRadius: '50%',
		padding: 0,

		'& .material-icons': {
			margin: 0,
			fontSize: 22,
		},

		[breakpoints.up('sm')]: {
			height: 40,
			width: 40,
		},

		'&$xs': {
			height: 20,
			width: 20,

			'& .material-icons': {
				fontSize: 16,
			}
		},

		'&$sm': {
			height: 30,
			width: 30,

			'& .material-icons': {
				fontSize: 18,
			}
		},

		'&$lg': {
			height: 60,
			width: 60,

			'& .material-icons': {
				fontSize: 52
			}
		},

		'&$auto': {
			height: 'auto',
			width: 'auto',
		}
	},

	// opacity
	opacity: {
		'&$disabled': {
			'&, &:focus, &:hover, &:visited': {
				color: secondaryColor + ' !important'
			}
		},

		'&$primary': {
			'&, &:focus, &:hover, &:visited': {
				backgroundColor: primaryLightColor,
				color: primaryColor,
			}
		},

		'&$danger': {
			'&, &:focus, &:hover, &:visited': {
				backgroundColor: dangerLightColor,
				color: dangerColor,
			}
		},

		'&$warning': {
			'&, &:focus, &:hover, &:visited': {
				backgroundColor: warningLightColor,
				color: warningColor,
			}
		},

		'&$black': {
			'&, &:focus, &:hover, &:visited': {
				backgroundColor: secondaryLightColor,
				color: dirtGreyColor,
			}
		},
	},

	// simple
	simple: {
		backgroundColor: 'transparent',
		color: secondaryColor,
		boxShadow: 'none',

		'&:focus, &:hover, &:visited': {
			backgroundColor: 'transparent',
			color: secondaryDarkColor,
		},

		'&$primary': {
			'&, &:focus, &:hover, &:visited': {
				color: primaryColor,
			}
		},

		'&$danger': {
			'&, &:focus, &:hover, &:visited': {
				color: dangerColor,
			}
		},

		'&$warning': {
			'&, &:focus, &:hover, &:visited': {
				color: warningColor,
			}
		},

		'&$white': {
			'&, &:focus, &:hover, &:visited': {
				color: whiteColor,
			}
		},

		'&$black': {
			'&, &:focus, &:hover, &:visited': {
				color: secondaryDarkColor,
			}
		},

		// if justIcon with simple variant
		'&$justIcon': {
			'&:hover, &:focus, &:visited': {
				backgroundColor: 'rgba(0, 0, 0, 0.04) !important'
			}
		}
	}
}

export default buttonStyle
