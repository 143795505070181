export default {
	module: 'users',
	actions: {
		find: true,
		create: true,
		edit: true,
		dynamicPaths: [{
			keys: {
				':id': 3
			},
			paths: {
				'/users/edit/:id': true
			}
		}, {
			keys: {
				':search_type': 3,
				':q': 4,
			},
			paths: {
				'/users/find/:search_type/:q': true
			}
		}]
	}
}
