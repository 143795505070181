import React from 'react'

import RawTypography from '@material-ui/core/Typography'

import IconWrapper from './wrappers/icon'
import Wrapper from './wrappers/wrapper'
import addEvent from 'src/submodules/baseApp/common/utils/addEvent'

// make the typography also log any onclick's to the event server
const Typography = ({
	onClick,
	children,
	...rest
}) => {
	return (
		<RawTypography
			onClick={addEvent({
				onClick,
				title: rest.title,
				button: rest.identifier || rest.id,
			})}
			{...rest}
		>
			{children}
		</RawTypography>
	)
}

export const Title = ({children, ...rest}) =>
	<Typography {...rest} variant='h5'>
		{children}
	</Typography>

export const Title2 = ({children, ...rest}) =>
	<Typography {...rest} variant='h1'>
		{children}
	</Typography>

export const Header = ({children, ...rest}) =>
	<Typography variant='subtitle2' paragraph component={Wrapper} {...rest}>
		{children}
	</Typography>

export const Header2 = ({children}) =>
	<Typography variant='subtitle1' paragraph>
		{children}
	</Typography>

export const HeaderIcon = ({icon, children, ...rest}) =>
	<TextWithIcon icon={icon} color='inherit' style={{marginBottom: 12}} {...rest}>
		<Header color='inherit' style={{margin: 0}}>
			{children}
		</Header>
	</TextWithIcon>

export const HeaderRequired = ({children, variant, disabled, required}) =>
	<Typography variant={variant || 'subtitle2'} color={disabled ? 'secondary' : 'default'}>
		<Wrapper alignItems='center'>
			{children}

			{required &&
				<Typography variant={variant || 'subtitle2'} color={disabled ? 'secondary' : 'error'} component='span'>&nbsp;*</Typography>
			}
		</Wrapper>
	</Typography>

export const Small = ({children, bold, color, alignItems, component, ...rest}) =>
	<Typography variant='body1' color={color || 'inherit'} component={component || Wrapper} {...rest}>
		<Wrapper alignItems={alignItems} component={bold ? 'b' : 'span'}>
			{children}
		</Wrapper>
	</Typography>

export const Caption = ({children, ...rest}) =>
	<Small color='textSecondary' component='span' {...rest}>{children}</Small>

export const Error = ({children, ...rest}) =>
	<Small color='error' {...rest}>
		{children}
	</Small>

export const Normal = ({children, ...rest}) =>
	<Typography variant='body2' {...rest}>
		{children}
	</Typography>

export const Bold = ({children, ...rest}) =>
	<Typography
		component='strong'
		style={{
			fontWeight: 700,
			display: 'inline'
		}}
		{...rest}
	>
		{children}
	</Typography>

export const Primary = ({children, ...rest}) =>
	<Typography color='primary' component={Wrapper} {...rest}>
		{children}
	</Typography>

export const Secondary = ({children, ...rest}) =>
	<Typography color='textSecondary' component={Wrapper} {...rest}>
		{children}
	</Typography>

export const TextWithIcon = ({icon, iconSize, color, children, text, textVariant, ...rest}) => (
	<Wrapper alignItems='center' {...rest}>
		{icon &&
			<IconWrapper size={iconSize || false} color={color || false}>
				{icon}
			</IconWrapper>
		}

		{children ?
			children :
			<Typography variant={textVariant || 'body1'} color={color || 'default'} component='span'>
				{text}
			</Typography>
		}
	</Wrapper>
)
