import {
	mixin,
	escapeRegExp,
	omit,
	isNumber,
	find,
	attempt,
	includes,
	merge,
} from 'lodash'

import moment from 'moment'
import loadCSS from 'fg-loadcss'

import App from 'submodules/baseApp/base'

import mixins from './lodashMixins'

import {
	lsGet,
	lsSet,
	lsRemove,
	lsClearAll,
	lsUpdate,
} from './utils/localstorage'

import {
	generateId,
	calculatePercent,
	openTab,
} from './utils/misc'

import {
	modalReact,
} from './utils/modal'

import {
	loadFile,
} from './utils/file'

import {
	cookiesEnabled,
	cookieGet,
	cookieAxe,
	cookieWithConverter,
	cookieSet,
} from './utils/cookie'

export {
	openTab,
	generateId,
	calculatePercent,
	cookiesEnabled,
	cookieGet,
	cookieAxe,
	cookieWithConverter,
	cookieSet,
}

let UtilsDefault

App.module('Common.Utils', function(Utils) {

	// mixins for the underscore lib
	mixin(mixins);

	Utils.cookiesEnabled = cookiesEnabled
	Utils.cookieGet = cookieGet
	Utils.cookieAxe = cookieAxe
	Utils.cookieWithConverter = cookieWithConverter
	Utils.cookieSet = cookieSet

	/**
	* Checks whether URL passed in is external, and is outside the current apex domain name (dragonlaw.io).
	*
	* @returns url | false {str|bool} - returns 'url' if true, false if not
	*/
	Utils.isExternalURL = (url) => {
		// can be "http://", "https://" or schemeless "//"
		let httpRegex = new RegExp(/^((http|https):\/\/)+|^(\/\/)(?:[A-z0-9])/),
			dragonRegex = new RegExp(App.getConfig('siteLinks').baseHost)

		// make regex-readable
		url = decodeURIComponent(url)

		// if the url doesn't have any 'dragonlaw.io' in it, and is set with
		// "http://"; "https://"; "//" then return the URL, else return false
		if (!dragonRegex.test(url) && httpRegex.test(url) && !/localhost/.test(url)) {
			return url
		}

		return false
	}

	Utils.openTab = openTab

	/**
	* Checks whether URL passed in is external, includes the current apex domain name (dragonlaw.io),
	* and does NOT contain the current hostname (hatchery(...), app.web-test(...)). Also excludes 'localhost'.
	*
	* @returns url | false {str|bool} - returns 'url' if true, false if not
	*/
	Utils.isExternalDragonURL = (url) => {
		// setup regex to test if they are redirecting to anywhere
		// within dragonlaw.io base domain
		let dragonRegex = new RegExp(App.getConfig('siteLinks').baseHost),
			httpRegex = new RegExp(/^((http|https):\/\/)+/),
			hostnameRegex = new RegExp(document.location.hostname)

		// make regex-readable
		url = decodeURIComponent(url)

		if (dragonRegex.test(url) && !hostnameRegex.test(url) && httpRegex.test(url) && !/localhost/.test(url)) {
			return url
		}

		return false
	}

	Utils.isMediaSmall = function() {
		return false
		// return window.matchMedia(Foundation.media_queries['small-only']).matches;
	};

	Utils.lsGet = lsGet

	Utils.lsSet = lsSet

	Utils.lsRemove = lsRemove

	Utils.lsClearAll = lsClearAll

	Utils.lsUpdate = lsUpdate

	// safely parse json without try catch
	Utils.parse = function(string) {
		return attempt(JSON.parse.bind(null, string));
	};

	Utils.APIDate = function(date, inputFormat = 'MMMM Do YYYY') {
		return moment(date, inputFormat).format();
		// return moment(new Date(date)).format();
	};

	Utils.watchEnter = function(e, value) {
		var k = e.keyCode || e.which;
		if (k === 13 && value === '') {
			e.preventDefault();
			return false;
		} else if (k === 13) {
			e.preventDefault();
			return true;
		}

		return false;
	};

	Utils.calculatePercent = calculatePercent

	Utils.generateId = generateId

	// safe regex replace
	Utils.replaceAll = function(string, find, replace) {
		return string.replace(new RegExp(escapeRegExp(find), 'g'), replace);
	};

	// Utils.scrollTo = function(element, offset = 0, delay = 1000) {
	// 	if (element.length === 0) {
	// 		return
	// 	}

	// 	$('html, body').animate({
	// 		scrollTop: element.offset().top - offset
	// 	}, delay);
	// };

	/**
	* Update a sub string in a string.
	*
	* @param {string} string
	* @param {string} find
	* @param {string} replace
	*
	* @return {string} new updated string
	*/
	Utils.updateStr = function(str, find, replace) {
		if (!find) {
			return str;
		}

		// str.split(search).join(replacement);
		// way faster than regex in v8! but also generally safer
		return str ? str.split(find).join(replace || '') : '';
	};

	/**
	* Get the url options after the hash in an object
	*
	* @returns {object}
	*/
	// Utils.parseUrl = function() {
	// 	return chain(App.getCurrentRoute().split('&'))
	// 		// Split each array item into [key, value]
	// 		// ignore empty string if search is empty
	// 		.map(function(item) { if (item) { return item.split('='); } return [] })
	// 		// Remove undefined in the case the search is empty
	// 		.compact()
	// 		// Turn [key, value] arrays into object parameters
	// 		.zipObject()
	// 		// Return the value of the chain operation
	// 		.value();
	// };

	/**
	* Compare
	*
	* @param {string}
	* @param {string}
	* @param {string} mode - Sort order
	*/
	Utils.compare = function(a, b, mode, field = '_modified', type = null) {
		var fieldA = a.get(field),
			fieldB = b.get(field);

		// console.log('fieldA', fieldA);
		// console.log('fieldB', fieldB);

		if (fieldA === undefined || fieldB === undefined) {
			// App.log('Missing compare field:', field, 'Common Util', 2);
			return mode === 'reverse' ? 1 : -1;
		}

		// try automatic detection
		if (type === null) {
			if (isNumber(fieldA)) {
				type = 'number'
			} else {
				type = 'string'
			}
		}

		if (mode === 'reverse') {
			if (type === 'date') {
				// console.log('Date result:', result)
				return moment(fieldA).isBefore(fieldB) ? 1 : -1
			}

			if (type === 'number') {
				return parseInt(fieldA, 10) < parseInt(fieldB, 10) ? 1 : -1
			}

			// default is string
			// if (isString(fieldA) && type === 'string') {
			return -fieldA.localeCompare(fieldB)
			// }
		} else {
			if (type === 'date') {
				return moment(fieldA).isAfter(fieldB) ? 1 : -1
			}

			if (type === 'number') {
				return parseInt(fieldA, 10) < parseInt(fieldB, 10) ? -1 : 1
			}

			// default to string
			return fieldA.localeCompare(fieldB)
		}
	};

	Utils.sortCollection = function(a, b, mode = 'asc', field = '_modified', type = 'string') {
		var value1 = a.get(field);
		var value2 = b.get(field);
		// console.log('1', value1);
		// console.log('2', value2);
		// console.log('mode', mode);

		if (value1 !== undefined && value2 !== undefined) {
			if (type === 'string') {
				if (value1.localeCompare(value2) === -1) {
					// console.log(value1 + ' > ' + value2);
					return (mode === 'asc') ? -1 : 1;
				} else if (value1.localeCompare(value2) === 1) {
					// console.log(value2 + ' > ' + value1);
					return (mode === 'asc') ? 1 : -1;
				} else {
					return 0;
				}
			} else if (type === 'date') {
				var date1 = new Date(value1), date2 = new Date(value2);
				if (date1 < date2) {
					return (mode === 'asc') ? -1 : 1;
				} else if (date1 > date2) {
					return (mode === 'asc') ? 1 : -1;
				} else {
					return 0;
				}
			} else {
				if (value1 < value2) {
					return (mode === 'asc') ? -1 : 1;
				} else if (value1 > value2) {
					return (mode === 'asc') ? 1 : -1;
				} else {
					return 0;
				}
			}
		} else {
			return 0;
		}
	};

	Utils.reverseCompare = function(a, b) {
		return App.Common.Utils.compare(a, b, 'reverse');
	};

	// Utils.removeStylesheet = function(name) {
	// 	$('link[rel=stylesheet][href~="styles/' + name + '.css"]').remove();
	// };

	Utils.addStylesheet = (name) => {
		// console.log('addStylesheet:', name);
		if (find(document.styleSheets, {title: name})) {
			// console.log('Style sheet already exists:', name);
			App.radio.trigger('stylesheet:loaded:' + name);
			return
		}

		// console.log('Trying to load:', stylesheetPath);
		let stylesheet = loadCSS('styles/' + name + '.css'); // eslint-disable-line no-undef

		onloadCSS(stylesheet, function() { // eslint-disable-line no-undef
			// console.log('Stylesheet has loaded.', name);
			App.radio.trigger('stylesheet:loaded:' + name);
		});
	};

	/**
	 * Upload a file (from import/export)
	 *
	 * @param {object} context - View
	 * @param {string} [id] - Dom id
	 * @param {bool} [noParse] - True: Do not parse as json
	 */
	Utils.upload = function(context, id, noParse) {
		var self = context,
			reader = new FileReader(),
			file = self.el.querySelector(id || '#import-file-template').files[0];

		if (file) {
			var filename = file.name.split('.')[0];
			reader.onload = function() {
				// in a try catch because the json parse might fail
				if (noParse) {
					var splitResult = this.result.match(/^data:.+\/(.+);base64,(.*)$/);
					splitResult = splitResult.length > 1 ?
						splitResult[2] :
						this.result;
					self.trigger('imported', splitResult, filename);
				} else {
					try {
						// console.log('Non parse mode:', noParse);
						var obj = noParse ? this.result : JSON.parse(this.result);
						self.trigger('imported', obj, filename);
					} catch (e) {
						App.radio.trigger('modal:hide');
						App.radio.trigger('message', App.t('general.import.invalid'));
						// console.error(e, obj);
					}
				}

			};

			if (noParse) { // base64 mode
				reader.readAsDataURL(file);
			} else { // JSON mode
				reader.readAsText(file);
			}
			// reader.readAsText(e.target.files[0]);
		} else {
			App.radio.trigger('message', App.t('general.import.noFile'));
		}
	};

	/**
	 * When triggered displays an overview modal
	 *
	 * @param {View} region - Region to display the modal
	 * @param {View} view - View to display
	 * @param {function} callback - Called when modal is closed
	 */
	Utils.modal = function(region, view, callback) {
		// create a temp view to render so we can access the html
		var tempView = view;
		tempView.render();
		var title = tempView.$el.find('[data-modal-title]').html();

		var modal = new App.Common.Views.Modal(merge(omit(view.options, 'template'), {
			callback,
			templateHelpers: {
				wide: this.options.wide,
				header: title || false,
				title: tempView.$el.find('[data-modal-title]').html(),
			}
		}));

		region.show(modal);

		// modal can be closed either by this event
		// or by directly calling the main App one
		// note we have to manually call the callback if we do this
		// since the behavior will not be doing it
		view.on('close', function(args) {
			if (callback) {
				// console.log('Callback found for modal');
				callback(args);
			}
			App.radio.trigger('modal:hide');
		});

		modal.content.show(view);

		return modal;
	};

	/**
	 * When triggered displays an overview modal - ReactJS Components only
	 *
	 * @param {Function} component - React Component to display
	 * @param {Object} props - Options
	 * @param {Function} callback - Callback to init when modal is closed
	 */
	Utils.modalReact = modalReact

	Utils.toggleClass = (item, class1 = 'is-expanded') => {
		if (includes(item, class1)) {
			return item.replace(class1, '')
		}

		return item + ' ' + class1
	}

	Utils.toggleClasses = (item, class1 = 'is-gone', class2 = 'is-expanded') => {
		if (includes(item, class1)) {
			return item.replace(class1, class2)
		} else {
			return item.replace(class2, class1)
		}
	}

	Utils.addScript = loadFile

	Utils.API = Utils;
	UtilsDefault = Utils
})

export default UtilsDefault

