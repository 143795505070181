import {map, find, compact, each, get} from 'lodash';
import React from 'react';
import {withRouter} from 'react-router-dom';
import {inject, observer} from 'mobx-react';

import App from 'app';
import Error from 'error';

App.dynamicPaths = [
	'details',
	'members',
	'subscriptions',
	'cards',
	'documents',
	'edit',
	'invoices',
];

@inject('user')
@observer
class Base extends React.Component {
	checkPermission = (path) => {
		let failReason = 0;
		const [, module] = path.split('/');
		// check if current path is allowed in user permissions
		let allowRoute = get(App.user_permissions, `${path}`) || false;

		if (!allowRoute) {
			failReason = 1;
			const moduleRoutes = App.user_permissions[module];
			allowRoute = get(moduleRoutes, `${path}`) || false;

			App.log(
				`${path}: `,
				{
					module,
					routes: get(moduleRoutes, `${path}`),
					moduleRoutes,
				},
				'Base Auth',
				2
			);

			const dynamicPaths = get(moduleRoutes, 'dynamicPaths');
			if (!allowRoute && dynamicPaths) {
				failReason = 2;
				each(dynamicPaths, (dynamicPath) => {
					failReason = 3;
					let currentPath = path.split('/');
					each(dynamicPath.keys, (val, key) => {
						failReason = 4;
						currentPath[val] = key;
					});
					allowRoute =
						get(moduleRoutes, `${currentPath.join('/')}`) || false;
					return !allowRoute; // return false [stop loop] if path matched
				});
			}
		} else {
			allowRoute = true;
		}
		if (!allowRoute) {
			App.log(
				'unauthed_route',
				{
					message: 'Route permission failed',
					path,
					module,
					failReason,
				},
				'Base Auth',
				1
			);
		}

		return allowRoute;
	};

	setupRoles = () => {
		const {authRoutes, history} = this.props;

		const roles = this.props.user.roles;

		App.user_permissions = [];
		App.user_permissions['/'] = true;
		App.user_permissions['/welcome'] = true;

		// adding public path to user permission
		// ie: http://localhost:3000/login http://localhost:3000/forget-password
		each(authRoutes, (route) => {
			App.user_permissions[route] = true;
		});

		let roleModules = map(roles, (val) => {
			return find(App.roleMap, {role: val});
		});

		roleModules = compact(roleModules);

		each(roleModules, (modules) => {
			each(modules.routes, (per) => {
				App.user_permissions[`${per.module}`] = [];
				// for default module route ie: http://localhost:3000/user
				App.user_permissions[`${per.module}`][`/${per.module}`] = true;
				each(per.actions, (permission, path) => {
					App.user_permissions[`${per.module}`][
						`/${per.module}/${path}`
					] = permission;
					if (path === 'dynamicPaths') {
						const dynamicPaths =
							App.user_permissions[`${per.module}`][
								`/${per.module}/${path}`
							];
						each(dynamicPaths, (_dynamicPath) => {
							each(_dynamicPath.paths, (_permission, _path) => {
								App.user_permissions[`${per.module}`][
									`${_path}`
								] = _permission;
							});
						});
						App.user_permissions[`${per.module}`]['dynamicPaths'] =
							dynamicPaths;
					}
				});
			});
		});

		// THIS MIGHT NOT BE NEEDED NOW?
		history.listen((location) => {
			// location is an object like window.location
			this.checkPermission(location.pathname);
		});

		// return this.checkPermission(location.pathname);
		return true; // Change while pushing changes
	};

	render() {
		return this.setupRoles() ? (
			<div>{this.props.children}</div>
		) : (
			<Error message='401 Unauthorized' />
		);
	}
}

export default withRouter(Base);
