export default {
	module: 'settings',
	actions: {
		list: true,
		create: true,
		dynamicPaths: [
			{
				paths: {
					'/settings': true,
				}
			},
			{
				paths: {
					'/settings/jurisdictions': true,
				}
			},
			{
				paths: {
					'/settings/market': true,
				}
			},
			{
				paths: {
					'/settings/jurisdictions/:id/edit': true,
				},
				keys: {
					':id': 3
				}
			},
			{
				paths: {
					'/settings/market/:id/edit': true,
				},
				keys: {
					':id': 3
				}
			},
			{
				paths: {
					'/settings/jurisdictions/create': true,
				}
			},
			{
				paths: {
					'/settings/market/create': true,
				}
			},
		]
	}
}
