export default 	{
	module: 'products',
	actions: {
		list: true,
		details: true,
		create: true,
		dynamicPaths: [
			{
				paths: {
					'/products/create/guide-categories': true,
				},
			},
			{
				paths: {
					'/products/create/guides/select/:guideId': true
				},
				keys: {
					':guideId': 5
				}
			},
			{
				paths: {
					'/products/:id/edit': true
				},
				keys: {
					':id': 2
				}
			},
			{
				paths: {
					'/products/select/:orgId': true,
				},
				keys: {
					':orgId': 3
				}
			}

		]
	}
}
