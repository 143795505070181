import React from 'react'

// nodejs library for concatinating classes
import classNames from 'classnames'
import PropTypes from 'prop-types'

// material-ui components
import withStyles from '@material-ui/core/styles/withStyles';
import MaterialPaper from '@material-ui/core/Paper';

import Button from '../buttons/button'
import paperStyle from '../assets/styles/paper/base'

/*
* Usage example
*
* <Paper
*	color='primary | secondary | danger | warning | transparent'
*	padding='xs | sm | lg | none'
*	collapsed='bool'
*	margin='sm | none'
*	border= 'dashed' | bool		(gives paper border)
*	opacity='bool'				(gives paper opacty color)
*	focus='bool'				(gives strong box shadow)
*	slat='bool'					(gives border bottom to its child element)
*	panel='bool'				(gives panel layout)
*	cover='bool'				(gives fullheigt and fullWidth layout)
*	className='string'
*	onClose='function'
* >
* 	'node | string'
* </Paper>
*
*/

function Paper(props) {
	const {
		children,
		value,
		classes,
		onClose,
		color,
		margin,
		padding,
		border,
		opacity,
		focus,
		slat,
		panel,
		cover,
		reset,
		className,
		...rest
	} = props

	let paperClasses = classNames({
		[classes.paper]: true,
		[classes[color]]: color,
		[classes.marginRatio + margin]: margin,
		[classes.paddingRatio + padding]: padding,
		[classes.paperBorder]: border,
		[classes.paperOpacity]: opacity,
		[classes.paperFocus]: focus,
		[classes.paperSlat]: slat,
		[classes.paperPanel]: panel,
		[classes.paperCover]: cover,
		[classes.paperReset]: reset,
		[className]: className,
	})

	const paperProps = {
		className: paperClasses,
		...rest
	}

	return (
		<MaterialPaper {...paperProps}>
			{onClose &&
				<Button
					onClick={onClose}
					color={color}
					justIcon
					size={padding === 'none' ? false : padding} /*Clear button size is same as the padding ratio of paper*/
					simple
					disableRipple
					className={classes.buttonClose}
				>
					clear
				</Button>
			}

			{children ? children : value}
		</MaterialPaper>
	)
}

Paper.propTypes = {
	classes: PropTypes.object.isRequired,
	color: PropTypes.oneOf([
		'primary',
		'secondary',
		'danger',
		'warning',
		'transparent',
		false,
	]),
	margin: PropTypes.oneOf([
		'xs',
		'sm',
		'lg',
		'none',
		false,
	]),
	padding: PropTypes.oneOf([
		'xs',
		'sm',
		'lg',
		'none',
		false
	]),
	border: PropTypes.oneOfType([
		PropTypes.oneOf([
			'dashed'
		]),
		PropTypes.bool
	]),
	opacity: PropTypes.bool,
	focus: PropTypes.bool,
	slat: PropTypes.bool,
	panel: PropTypes.bool,
	cover: PropTypes.bool,
	/*
	* reset boxShadow, border & margin.
	*/
	reset: PropTypes.bool,
	onClose: PropTypes.func,
}

export default withStyles(paperStyle)(Paper)
