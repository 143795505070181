import {
	focusShadow,
	primaryColor,
	primaryLightColor,
	primaryBackground,
	secondaryColor,
	secondaryLightColor,
	secondaryBackground,
	dangerColor,
	dangerLightColor,
	dangerBackground,
	warningColor,
	warningLightColor,
	warningBackground,
	breakpoints,
	scales,
	marginRatio,
	paddingRatio
} from '../../global.style.js'

const paperStyle = {
	paper: {
		width: '100%',
		marginBottom: scales.space.topBottom,
		padding: scales.space.topBottom + ' ' + scales.space.rightLeft,
		backgroundColor: '#fff',
		boxSizing: 'border-box',
		borderRadius: 3,
		boxShadow: 'none',
		overflow: 'visible',
		'-webkit-overflow-scrolling': 'touch',
		border: '1px solid',
		borderColor:
			'rgba(223, 224, 228, 0.6) rgba(223, 224, 228, 0.8) rgba(223, 224, 228, 0.9)',
		position: 'relative',

		[breakpoints.up('md')]: {
			marginBottom: 'calc(' + scales.space.topBottom + ' * 2)',
		},

		// conditions
		'&:empty': {
			display: 'none'
		},
	},

	// color (refrence property)
	primary: {
		...primaryBackground
	},

	secondary: {
		...secondaryBackground
	},

	danger: {
		...dangerBackground
	},

	warning: {
		...warningBackground
	},

	transparent: {
		'&, &:focus, &:hover': {
			backgroundColor: 'transparent',
		}
	},

	// padding
	paddingRatio,

	// margin
	marginRatio,

	paperBorder: {
		'&, &:focus, &:hover, &:visited': {
			backgroundColor: '#fff',
			borderColor:
				'rgba(223, 224, 228, 0.5) rgba(223, 224, 228, 0.7) rgba(223, 224, 228, 0.8)',
			color: 'inherit',
			overflow: 'hidden'
		},

		'&$primary': {
			borderColor: primaryColor + '!important'
		},

		'&$secondary': {
			borderColor: secondaryColor + '!important'
		},

		'&$danger': {
			borderColor: dangerColor + '!important'
		},

		'&$warning': {
			borderColor: warningColor + '!important'
		}
	},

	paperOpacity: {
		'&, &:focus, &:hover, &:visited': {
			backgroundColor: '#fff',
			color: 'inherit',
			borderColor: 'transparent',
			boxShadow: 'none',
		},

		'&$primary': {
			backgroundColor: primaryLightColor,
			color: 'inherit'
		},

		'&$secondary': {
			...secondaryBackground,
			color: 'inherit'
		},


		'&$danger': {
			backgroundColor: dangerLightColor,
			color: 'inherit'
		},

		'&$warning': {
			backgroundColor: warningLightColor,
			color: 'inherit'
		},
	},

	paperFocus: {
		border: 0,
		boxShadow: focusShadow,

		'&$primary': {
			backgroundColor: '#fff',
			color: '#414751',
			borderTop: '5px solid' + primaryColor
		},

		'&$danger': {
			backgroundColor: '#fff',
			color: '#414751',
			borderTop: '5px solid' + dangerColor
		},

		'&$warning': {
			backgroundColor: '#fff',
			borderTop: '5px solid' + warningColor
		},
	},

	paperSlat: {
		'& > *': {
			border: 0,
			borderBottom: '1px solid ' + secondaryLightColor,
			borderRadius: 0,
			boxShadow: 'none'
		},

		'& > *:last-child, & ul div:last-child > li': {
			borderBottom: 0
		},
	},

	paperPanel: {
		...secondaryBackground,
		border: 0,
		borderRadius: 0,
		boxShadow: 'none',

		'&$primary': {
			backgroundColor: primaryLightColor,
		},

		'&$danger': {
			backgroundColor: dangerLightColor,
		},

		'&$warning': {
			backgroundColor: warningLightColor,
		},

		'&$paperFocus': {
			borderLeft: '3px solid ' + primaryColor
		}
	},

	paperCover: {
		display: 'block',
		height: '100%'
	},

	paperReset: {
		// reset styles
		border: 'none',
		borderRadius: 'unset',
		boxShadow: 'none',
		marginBottom: 0,
	},

	// specificly designed for Drag and drop paper
	paperWrappable: {
		padding: '1.6rem 1.3rem 1.6rem 3.9rem',

		// If paper have wrap (Drag) icon
		'&.is__wrap': {
			paddingLeft: '0',
		}
	},

	// close button
	buttonClose: {
		position: 'absolute',
		right: 0,
		top: 0,
		zIndex: 11,

		'& *': {
			fontWeight: 600,
			opacity: .8,
		},

		'&:hover': {
			'& *': {
				opacity: 1,
				fontWeight: 600
			},
		}
	},
}

export default paperStyle
