export default {
	module: 'coupons',
	actions: {
		list: true,
		create: true,
		dynamicPaths: [
			{
				paths: {
					'/coupons/edit/:id': true,
				},
				keys: {
					':id': 3
				}
			},
			{
				paths: {
					'/coupons/history/:id': true,
				},
				keys: {
					':id': 3
				}
			}
		]
	}
}
