import React from 'react'
import {BrowserRouter} from 'react-router-dom'

export default (App) => {
	App.actions.setupModals = (WrapThemeProvider) => {
		App.actions.delete = (props) => {
			import('src/submodules/components-material/modals/delete').then((DeleteModal) => {
				App.actions.loadComponent(
					() => (
						<BrowserRouter>
							<WrapThemeProvider>
								<DeleteModal.default {...props} />
							</WrapThemeProvider>
						</BrowserRouter>
					),
					{provide: ['general']}
				)
			})
		}

		App.actions.showModal = ({Component, ...props}, options) => {
			import('src/submodules/components-material/modals/modal').then((Modal) => {
				App.actions.loadComponent(
					() => (
						<BrowserRouter>
							<WrapThemeProvider>
								<Modal.default {...props} >
									<Component {...props} />
								</Modal.default>
							</WrapThemeProvider>
						</BrowserRouter>
					),
					options
				)
			})
		}
	}
}
