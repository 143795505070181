export default {
	module: 'whitelabel',
	actions: {
		list: true,
		edit: true,
		details: true,
		create: true,
		dynamicPaths: [{}]
	}
}
