import React from 'react'

import Error from 'submodules/components-material/error'

const ErrorComponent = ({message}) => {
	return (
		<Error message={message} />
	)
}

export default ErrorComponent
