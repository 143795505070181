import each from 'lodash/each'
import isBoolean from 'lodash/isBoolean'
import isString from 'lodash/isString'
import map from 'lodash/map'
import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'mobx-react'

import {lsSet} from 'src/submodules/baseApp/common/utils/localstorage'

import App from './base'
import addCoreAjax from './core/ajax'
import addCoreRoute from './core/route'
import addCoreHeaders from './core/ajaxHeaders'
import addCoreLogs from './core/logs'
import addCoreConfig from './core/config'
import addCoreLoading, {addLoadingActions} from './core/loading'
import addErrorActions from './core/error'
import addModalActions from './core/modal'

// console.log('Loading baseapp core!');
// console.log('========================');

addCoreLogs(App)
addCoreAjax(App)
addCoreRoute(App)
addCoreHeaders(App)
addCoreLoading(App)
addCoreConfig(App)

// reload the app
App.restart = function(timeout = 0) {
	setTimeout(function() {
		lsSet('DragonRedirect', {url: window.location.href})
		window.location = '';
	}, timeout)
}

App.actions = {
	warn(message) {
		console.warn(message)
	},

	message(props) {
		import('submodules/components-material/message').then((message) => {
			const renderOn = document.getElementById('glb-message');
			App.actions.loadComponent(message.default, props, renderOn)
		})
	},

	fetch(url, method = 'GET', data) {
		// console.log('data', data);
		// console.log('method', method);

		const headers = Object.assign({'Content-Type': 'application/json'}, App.getHeaders());

		// const headers = Object.assign({'Content-Type': 'application/json'}, App.getCustomHeaders())
		// console.log('headers', headers);

		const checkStatus = ((response) => {
			if (response.ok) {
				return response;
			} else {
				const error = new Error(response.statusText);
				console.error(error);
				//return Promise.reject(error);
			}
		})

		return fetch(App.API + url, {
			method,
			headers,
			body: JSON.stringify(data),
			// credentials: 'include'
		}).then(checkStatus)
			.then((result) => result.json())
	},

	getMenus() {
		// console.log('App.menusLoaded', App.menusLoaded);
		return () =>
			<div>
				{map(App.stores.menu.getShowingMenus(), (Menu, idx) => <Menu key={'menu' + idx} />)}
			</div>
	},

	getRegion(region = 'main') {
		const officialRegion = App.getConfig(`htmlIds.${region}`)

		if (officialRegion) {
			return document.getElementById(officialRegion)
		} else {
			console.warn('Trying to find un-findable region:', region)
			return false
		}
	},

	/**
	* Checks for React component existence in App
	* and unmounts if found - prevents memory leaks
	*  - calls public React.unmountComponentAtNode(DOM id)
	*  - should be a parent component so it cleans up the children comp's
	*
	*/
	unmountReactComponent(region = 'main') {
		if (typeof region === 'string') {
			region = this.getRegion(region)
		}

		const node = ReactDOM.findDOMNode(region)
		ReactDOM.unmountComponentAtNode(node)
	},

	/**
	* Wrap a page in a store providing wrapper
	*
	* @param {String} Component -
	* @param {String} options -
	*/
	wrapPage(Component, options = {}) {
		// hack to make React available after the bulid
		// because it will look for this var in the react codes
		// that do the jsx transforms

		// console.log('Wrapping Component', Component, options);

		class Wrapper extends React.Component {
			render() {
				let stores = {}

				// here is the logic to pick which store to use
				// so that we can pass any we like into the provider
				if (options.provide) {
					// console.log('options.provide', options.provide);

					// default all:
					if (isBoolean(options.provide)) {
						options.provide = ['general', 'store', 'user', 'orgs', 'contacts']
					}

					// add one:
					if (isString(options.provide)) {
						// console.log('From string:', options.provide);
						stores[options.provide] = App.stores[options.provide]
					} else {
						// console.log('From array:', options.provide);
						// add many:
						each(options.provide, (store) => {
							stores[store] = App.stores[store]
						})
					}
				}

				// console.log('Adding stores:', stores);

				return (
					<Provider {...stores}>
						<div>
							<Component {...this.props} {...options} />
						</div>
					</Provider>
				)
			}
		}

		return Wrapper
	},

	resetCache() {
		App.cache = {}
	},

	/**
	 * Set the title of the page
	 *
	 * @param titleKey string - i18n key for document title or string
	 */
	changeTitle(titleKey = '', options = {}) {
		// console.log('titleKey', titleKey);

		// skip all fancy stuff
		if (options.manual) {
			document.title = titleKey
			return
		}

		if (!App.t) {
			return
		}

		// test to see if there is a translation for this title
		let title = App.t(titleKey);

		title = title.toLowerCase() !== titleKey.toLowerCase() ? title : titleKey;

		let prefix = App.t('general.pageTitlePrefix')
		prefix = (prefix === 'general.pagetitleprefix' ? '' : prefix) + ' '

		let suffix = App.t('general.pageTitleSuffix')
		suffix = ' ' + (suffix === 'general.pagetitlesuffix' ? '' : suffix)

		document.title = `${prefix}${title}${suffix}`;
	},
}

// console.log('Setting up baseApp actions')
addErrorActions(App)
addLoadingActions(App)
addModalActions(App)

App.cache = {}

export default App
