import App from 'app'

import {beautifulDomId} from 'src/submodules/baseApp/common/lodashMixins'

export default ({
	name = 'click_event',
	title,
	button,
	onClick
}) => {
	const newHandler = (event) => {
		if (!onClick) {
			return event
		}

		const data = {}

		if (title) {
			data.title = beautifulDomId(title)
		}

		if (button) {
			data.button = beautifulDomId(button)
		}

		App.event(name, {
			data
		})

		onClick(event)
	}

	return newHandler
}
