import {
	backDrop,
} from '../global.style'

const mdScreen = '@media only screen and (min-width: 1024px)'

export default {
	backDrop: {
		...backDrop,
	},

	dialog: {
		width: '100%',

		[mdScreen]: {
			minWidth: 600,
			width: 'auto',

			'&.lg': {
				width: 700,
			}
		}
	},

	dialogPaper: {
		margin: 12,
		maxHeight: 'calc(100% - 12px)',
		boxShadow:
			'0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .20)'
	},

	dialogTitle: {
		fontSize: '1rem',
		fontWeight: 700,
		padding: '32px 24px 28px'
	},

	dialogContent: {
		'&.collapsed': {
			padding: 0
		},

		'&::-webkit-scrollbar': {
			width: 8,
		},

		'&::-webkit-scrollbar-thumb': {
			borderRadius: 8,
			backgroundColor: '#ccc'
		}
	},

	dialogCloseButton: {
		position: 'absolute',
		right: 0,
		margin: 6
	}
}
