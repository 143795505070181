import React from 'react'

import Wrapper from '../assets/styles/wrapper/unstableWrapper'

/*
*	This component is the upgrade version of ../wrappers/wrapper
*
*	New upgraded wrapper need to support simple dynamic styling using styled-components
*	which will help desginers to their work easy, fast and clean.
*
*	Note: Please include on notation list before you add your new rules.
*
*	1) Spacing :
*	   m - margin
*	   p - padding
*	   
*	   additional sides
*	   t - margin-top (mt), padding-top (pt)
*	   r - margin-right (mr), padding-right (pr)
*	   b - margin-bottom (mb), padding-bottom (pb)
*	   l - margin-left (ml), padding-left (pl)
*	   x - (left and right) (mx, px)
*	   y - (top and bottom) (mt, pt)
*/

function UnstableWrapper(props) {
	return (
		<Wrapper {...props}>
			{props.children}
		</Wrapper>
	)
}

export default UnstableWrapper
