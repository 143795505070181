import {
	ms
} from '../../global.style'

import styled from 'styled-components'

const Wrapper = styled.div((props) => ({
	/* spacing */
	margin: props.m && ms(props.m),
	marginTop: props.mt && ms(props.mt),
	marginRight: props.mr && ms(props.mr),
	marginBottom: props.mb && ms(props.mb),
	marginLeft: props.ml && ms(props.ml),
	padding: props.p && ms(props.p),
	paddingTop: props.pt && ms(props.pt),
	paddingRight: props.pr && ms(props.pr),
	paddingBottom: props.pb && ms(props.pb),
	paddingLeft: props.pl && ms(props.pl),
}))

export default Wrapper
