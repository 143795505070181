import React from 'react'
import {observer, inject} from 'mobx-react'

import App from 'app'
import Dashboard from './status'

import {Title} from 'submodules/components-material/typography'
import Wrapper from 'submodules/components-material/wrappers/wrapper'
import PaperNotification from 'submodules/components-material/papers/notification'

@inject('general') @observer
class Welcome extends React.Component {
	render() {
		const {
			general,
		} = this.props

		return (
			<Wrapper inner='flat'>
				<Title
					under='header1'
					style={{marginBottom: '1.2rem', fontWeight: 'normal'}}
				>
					Welcome to Dragon Admin: v
					<span id='dragon-admin-version'>{App.getConfig('version')}</span>
				</Title>

				{general.newContent &&
					<Wrapper>
						<PaperNotification
							color='primary'
							icon='info'
							title={general.newContent}
							onClose={() => {
								general.newContent = undefined
							}}
						/>
					</Wrapper>
				}

				<Dashboard />
			</Wrapper>
		)
	}
}

export default Welcome
