import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import {withStyles} from '@material-ui/core/styles'

import wrapperStyle from '../assets/styles/wrapper/base'

function Wrapper(props) {
	const {
		under,
		margin,
		inner,
		display,
		position,
		flex,
		alignItems,
		alignSelf,
		justify,
		direction,
		className,
		children,
		classes,
		component,
		...rest
	} = props

	const WrapperComponent = component || 'div'

	const wrapperClassName = classNames({
		[classes.wrapper]: true,
		[classes.wrapperUnder + under]: under, // wrapper for content under fixed header
		[classes.marginRatio + margin]: margin,
		[classes.wrapperInner + inner]: inner, // inner wrapper for content
		[classes.wrapperDisplay + display]: display,
		[classes.wrapperPosition + position]: position,
		[classes.wrapperFlexAuto + flex]: flex,
		[classes.wrapperFlexAlignItems + alignItems]: alignItems,
		[classes.wrapperFlexAlignSelf + alignSelf]: alignSelf,
		[classes.wrapperFlexjustifyContent + justify]: justify,
		[classes.wrapperDirection + direction]: direction,
		[className]: className
	})

	const wrapperProps = {
		className: wrapperClassName,
		...rest
	}

	return (
		<WrapperComponent {...wrapperProps}>
			{children}
		</WrapperComponent>
	)
}

Wrapper.propTypes = {
	classes: PropTypes.object.isRequired,
	under: PropTypes.oneOf([
		'header',
		'header1',
		'header2',
	]),
	margin: PropTypes.oneOf([
		'xs',
		'sm',
		'lg',
		'none',
		false,
	]),
	inner: PropTypes.oneOf([
		'flat',
		'compress',
		'compress1',
		'compress2'
	]),
	display: PropTypes.oneOf([
		'flex',
		'inline-flex',
		'block',
		'cover',
		'mask',
		'fullWidth'
	]),
	position: PropTypes.oneOf([
		'vertical-center',
		'horizontal-center',
		'total-center',
		'relative',
		'absolute',
	]),
	flex: PropTypes.oneOf([
		'one',
		'two',
	]),
	alignItems: PropTypes.oneOf([
		'flex-start',
		'center',
		'baseline',
		'flex-end',
		'stretch',
		false,
	]),
	alignSelf: PropTypes.oneOf([
		'flex-start',
		'center',
		'baseline',
		'flex-end',
		false,
	]),
	justify: PropTypes.oneOf([
		'space-between',
		'space-around',
		'flex-start',
		'flex-end',
		'center',
		false,
	]),
	direction: PropTypes.oneOf([
		'row',
		'row-md-up',
		'column',
		'column-md-up',
	])
}

export default withStyles(wrapperStyle)(Wrapper)
