import React from 'react'

// nodejs library for concatinating classes
import classNames from 'classnames'
import PropTypes from 'prop-types'

// material-ui components
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles';

import Paper from './paper'
import Wrapper from '../wrappers/wrapper'
import IconWrapper from '../wrappers/icon'
import paperActionStyle from '../assets/styles/paper/action'

/*
* Usage example
*
* <PaperAction
*	icon='node | iconName'
*	title='node | string'
*	action='node | string'
* >
* 	'node | string'
* </PaperAction>
*
*/

export const Content = ({title, children, value, className}) => {
	return (
		<Wrapper className={className}>
			{title &&
				<Typography variant='subtitle1' gutterBottom>
					{title}
				</Typography>
			}

			{children ?
				children : value
			}
		</Wrapper>
	)
}

export const Action = ({action, className}) => {
	if (action) {
		return (
			<Wrapper className={className}>
				{action}
			</Wrapper>
		)
	} else return null
}

export const Footer = ({footer, className}) => {
	if (footer) {
		return (
			<Wrapper className={className}>
				{footer}
			</Wrapper>
		)
	} else return null
}

export const PaperAction = (props) => {
	const {
		icon,
		title,
		children,
		value,
		action,
		footer,
		classes,
		className,
		...rest
	} = props

	const paperActionClasses = classNames({
		[classes.paperActionWrapper]: true,
		[className]: className
	})

	return (
		<Paper {...rest}>
			<Wrapper className={paperActionClasses}>
				<Wrapper display='flex' flex='two'>
					{icon &&
						<IconWrapper color='primary' size='md' style={{margin: '6px 0 0 6px'}}>{icon}</IconWrapper>
					}

					<Content
						title={title}
						children={children}
						value={value}
						className={classes.paperContent}
					/>
				</Wrapper>

				<Action
					action={action}
					className={classes.paperAction}
				/>
			</Wrapper>

			{/*Footer*/}
			<Footer
				footer={footer}
				className={classes.paperFooter}
			/>
		</Paper>
	)
}

PaperAction.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default withStyles(paperActionStyle)(PaperAction)
