import {breakpoints, scales} from '../../global.style.js'

const paperActionStyle = {
	paperActionWrapper: {
		display: 'flex',
		flexDirection: 'column',

		[breakpoints.up('md')]: {
			flexDirection: 'row',
			alignItems: 'flex-start'
		}
	},

	paperContent: {
		padding: '0 12px'
	},

	paperAction: {
		marginTop: '.6rem',

		[breakpoints.up('md')]: {
			marginTop: 0,
			marginRight: scales.space.rightLeft,
		}
	},

	paperFooter: {
		margin:
			scales.space.topBottom + ' -' + scales.space.rightLeft + ' -' + scales.space.topBottom,
		padding: '.6rem ' + scales.space.rightLeft,
		background: '#ffffff'
	}
}

export default paperActionStyle
