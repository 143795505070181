import React from 'react';
import PropTypes from 'prop-types'
import {
	map,
} from 'lodash'

import classNames from 'classnames'
import {withStyles} from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Wrapper from '../wrappers/wrapper'
import Button from '../buttons/button';
import IconWrapper from '../wrappers/icon'
import modalStyles from '../assets/styles/modal'

class DialogComponent extends React.Component {
	static propTypes = {
		handleClick: PropTypes.func,
		buttons: PropTypes.array,
		customMode: PropTypes.bool, // if custom mode, then don't show the dialog wrapping

		// styling props
		collapsed: PropTypes.bool,
		size: PropTypes.oneOf([
			'lg',
			false
		]),
	};

	static defaultProps = {
		open: true
	}

	state = {
		open: this.props.open,
	}

	componentWillReceiveProps(newProps) {
		this.setState({open: newProps.open})
	}

	handleClickOpen = () => {
		this.setState({open: true})
	}

	handleButton = (button) => () => {
		this.handleRequestClose()

		if (this.props.handleClick) {
			this.props.handleClick(button)
		} else {
			button.handleClick && button.handleClick()
		}
	}

	handleRequestClose = (event, reason) => {
		if (this.props.handleOnClose) {
			this.props.handleOnClose(event, reason)
		}

		this.setState({open: false})
	}

	render() {
		const {
			title,
			customMode,
			subtitle,
			buttons,
			children,
			classes,
			showCloseButton, // for to show close button
			onExit, // using for unit-test
			size,
			collapsed,
		} = this.props

		const WrappedChildren = React.Children.map(children, (child) => {
			// it's a React component, so add props
			if (typeof child.type === 'function') {
				return React.cloneElement(child, {
					handleClose: this.handleRequestClose,
					title: DialogTitle,
				})
			} else {
				// regular element, React yells when props are are passed onto it
				return React.cloneElement(child)
			}
		})

		// class distribution
		let dialogClassName = classNames({
			[classes.dialog]: true,
			[classes.dialogPaper]: true,
			size,
		})

		let dialogContentClassName = classNames({
			[classes.dialogContent]: true,
			'collapsed': collapsed
		})

		return (
			<Dialog
				open={this.state.open}
				onClose={this.handleRequestClose}
				classes={{
					paper: dialogClassName
				}}
				BackdropProps={{
					className: classes.backDrop
				}}

				// using for unit-test
				onExit={onExit}
			>
				{showCloseButton &&
					<IconWrapper
						isHover
						className={classes.dialogCloseButton}
						onClick={this.handleRequestClose}
					>
						clear
					</IconWrapper>
				}

				{title &&
					<DialogTitle disableTypography classes={{root: classes.dialogTitle}}>
						<Wrapper style={{marginBottom: subtitle ? 8 : 0}}>
							{title}
						</Wrapper>

						{subtitle &&
							<DialogContentText color='default' variant='body1'>
								{subtitle}
							</DialogContentText>
						}
					</DialogTitle>
				}

				{customMode
					? WrappedChildren
					: <DialogContent className={dialogContentClassName}>
						{WrappedChildren}

						{buttons &&
							<Wrapper alignItems='center'>
								{map(buttons, (button) =>
									<Button
										key={button.text}
										onClick={this.handleButton(button)}
										color='primary'
										simple={button.type === 'text' ? true : false}
										disableRipple
									>
										{button.text}
									</Button>
								)}
							</Wrapper>
						}
					</DialogContent>
				}
			</Dialog>
		);
	}
}

export default withStyles(modalStyles)(DialogComponent)
