import {
	isString,
} from 'lodash'
import React from 'react'
import ReactDOM from 'react-dom'
import {
	// Link,
	BrowserRouter as Router,
} from 'react-router-dom'

import Loading from 'src/submodules/components-material/progress/circular'

import Loadable from 'react-loadable';

const LoadingComponent = (props) => {
	if (props.isLoading) {
		// While our other component is loading...
		if (props.timedOut) {
			console.warn('Loader timed out!')
			// console.trace()
			// In case we've timed out loading our other component.
			return <div>&nbsp;</div>;
			// return <div>Loader timed out!</div>;
		} else if (props.pastDelay) {
			// Display a loading screen after a set delay.
			return <Loading />;
		} else {
			// Don't flash "Loading..." when we don't need to.
			return null;
		}
	} else if (props.error) {
		console.error(props)
		// If we aren't loading, maybe
		return <div>Error! Component failed to load</div>;
	} else {
		console.warn(props)
		// This case shouldn't happen... but we'll return null anyways.
		return null;
	}
}

export const loadFile = (args) => {
	return Loadable(Object.assign({
		loading: LoadingComponent,
		delay: 200,
		timeout: 200,
	}, args));
}

export default (App) => {
	App.loadFile = loadFile
}

export const addLoadingActions = (App) => {

	App.actions.loading = (showGlobal) => {
		App.actions.loadPage(Loading, {}, showGlobal ? '#glb-loading' : undefined)
	}

	App.actions.loadComponent = (Component, options = {}, location) => {
		// give modals the store too
		const FinalForm = options.provide ? App.actions.wrapPage(Component, options) : Component

		return ReactDOM.render(
			React.createElement(FinalForm, options), location || document.getElementById('glb-modal')
		)
	}

	App.actions.loadPage = (Component, options = {}, location) => {
		// console.log('App.pageRegion', App.pageRegion);
		// console.log('Load Component:', Component);
		// console.log('options', options);
		// console.log('Rendering to location:', location);

		if (!location) {
			// console.log('App.pageRegion', App.pageRegion);
			location = '#glb-content'
		}

		if (isString(location)) {
			const locationString = location[0] === '#' ? location.slice(1) : location
			location = document.getElementById(locationString)
			// location = ReactDOM.findDOMNode(location)
			if (!location) {
				App.log('app_error', {
					message: 'Cannot find location to render component: ' + locationString
				})
			}
		}

		// always be react for now
		App.actions.unmountReactComponent(location)

		const FinalForm = options.provide ? App.actions.wrapPage(Component, options) : Component

		const WithRouter = () =>
			<Router store={App.stores.general}>
				<FinalForm {...options} />
			</Router>

		return ReactDOM.render(
			React.createElement(WithRouter, options), location
		)
	}
}
