
export default {

	/**
	*
	* Development CONFIG (Dev)
	*
	*/
	platform: 'admin',

	// apis
	APIRoot: 'https://api.dev.drabayashi.net', // Dev
	APPRoot: 'https://dte-app.dev.drabayashi.net', // Dev
	APIPort: '443',
	SocketPort: '443',
	paymentsRoot: 'https://payments.dev.drabayashi.net', // Dev
	registrationRoot: 'https://register.dev.drabayashi.net',
	PartnerRoot: 'https://partner-admin.dev.drabayashi.net/partner-sites',
	// logServer: 'http://192.168.0.21:3000/logevents?name=',
	// logServer: false,

	// 0 - Prod (Only log errors)
	// 1 - Log to Kafka
	// 2 - Log in console
	// 3 - Just plain crazy
	logDisplayLevel: 2,
	logSaveLevel: 1,
	logLimit: 3,

	features: {
		auth: {
			disableStatusCheck: false,
			disableAPIKey: true,
			disableDash: false,
			disableShowDL2Login: true
		},

		registration: false,
		cookies: {
			enabled: true,
			secure: false,
			register: 'DragonRegister',
			domain: 'drabayashi.net',
		},

	},

	Payments: {
		stripe: {
			HKG: 'pk_test_SIFUJZEk4803VP6DbuhoWc3Q',
			DEFAULT: 'pk_test_SIFUJZEk4803VP6DbuhoWc3Q', // <- required!!

			// old
			// SGP: 'pk_test_HvBehlPNbr8yjWSoieZWHMZO'
		}
	},

	domain: 'admin',
	usePatch: false,
	ajaxMode: 'Backbone',
	maxAjaxRetries: 0,
	debug: true, // add app to window etc
	throwErrors: true,
	disableExtraCaching: false,
	siteLinks: {},
	whiteLabel: false,
	cardExpireCheck: false,
	seeDisclaimer: false,

	integrations: {
		mixpanel: {
			enabled: false,
		},
	}
}
