export default {
	module: 'scribes',
	actions: {
		list: true,
		create: true,
		edit: true,
		json: true,
		search: true,
	}
}
