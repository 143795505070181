import moment from 'moment'

import App from 'app'

if (App && App.getConfig('debug')) {
	window.moment = moment
}

/**
* THIS UTIL IS TO ABSTRACT MOMENT
*
* So we can remove it easily later on
*
*/

export const formatAMPM = 'h:mm A'
export const formatAMPMSmall = 'h:mm a'

/**
* Returns a date format of the passed date string.
* @example
* // https://momentjs.com/docs/#/parsing/creation-data/
* getFormatOfDate('2016-01-01') // -> 'YYYY-MM-DD'
* @param {string} date
* @returns {string} String representation of the format of the passed date string.
*/
export const getFormatOfDate = (date) => moment(date).creationData().format

// default get ISO current date: 2019-01-29T10:42:14+05:45
export const getDate = (date, format) => {
	return moment(date).format(format)
}

export const utc = () => {
	return moment.utc().format()
}

export const parseDate = (date, format, outputFormat) => {
	return moment(date, format).format(outputFormat)
}

// add days to current time
export const add = (days, date, format) => {
	const added = moment(date).add(days, 'days')

	if (!format) {
		return added.format()
	}

	// console.log('added.format()', added.format());
	// console.log('added.utc()', added.utc());
	// console.log('added.utc().format()', added.utc().format());

	return added.format(format)
}

// input: 2019-01-25T05:12:07.746Z
// returns => 12/12/2018 10:24 AM
export const display = (date) => {
	return getDate(date, 'DD/MM/Y h:mm A')
}

export const displaySimple = (date) => {
	return getDate(date, 'MMMM Do YYYY')
}

// returns => December 12th 2018, 10:25:24 am
export const displayLong = (date) => {
	return getDate(date, 'MMMM Do YYYY, h:mm:ss a')
}

// returns => 2018 11 04
export const sortableDate = (date) => {
	return getDate(date, 'YYYY MM DD')
}

// returns => Wed, Dec 12th 2018, 10:25:59 am
export const displayLongest = (date) => {
	return getDate(date, 'ddd, MMM Do YYYY, h:mm:ss a')
}

// returns => 12 Dec 2018, 10:26 am
export const displayLong2 = (date, format = getFormatOfDate(date)) => {
	return parseDate(date, format, 'D MMM YYYY, hh:mm a')
}

//
// NEW STYLE:
//

export const dateFormat1 = (date) => {
	return getDate(date, 'MMMM Do YYYY')
}

export const dateFormat2 = (date) => {
	return getDate(date, 'MMMM Do YYYY, h:mm:ss a')
}

// output: 1 February 2019
export const dateFormat3 = (date, parse = false) => {
	const format = 'D MMMM YYYY'

	if (parse) {
		return parseDate(date, format, format)
	}

	return getDate(date, format)
}

export const dateFormat4 = (date) => {
	return getDate(date, 'D MMM YYYY')
}

//
// Date calculations
//

export const isAfter = (date, afterDate, format = '') => {
	return moment(date, format).isAfter(afterDate)
}

export const isBefore = (date, beforeDate, format = '') => {
	return moment(date, format).isBefore(beforeDate)
}

export const fromNow = (date) => {
	return moment(date).fromNow()
}

//
// NOT RECOMMENDED:
//

export const getDateLibRaw = () => moment
