import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import {
	branch,
	compose,
	withState,
	withHandlers,
	renderComponent,
} from 'recompose'

import App from 'app'
import Form from './form'
import Svg from './svgs'

import ComponentButton from 'submodules/components-material/buttons/button'
import Spinner from 'submodules/components-material/progress/circular'

import Grid from '@material-ui/core/Grid';

/**
*
* THIS FILE IS CALLED: common_components
*
*/

const Components = {};

// don't use fragment
// since this is used in places in DL2 app,
// which is react 15
// export const Aux = Fragment
export const Aux = (props) => {
	return <div>{props.children}</div>
}
Components.Aux = Aux
Components.Aux.displayName = 'Aux';
Aux.propTypes = {
	children: PropTypes.any,
}

const Mandatory = (text = '') => {
	throw new Error('Required parameter(s) missing!', text)
}

export const Title = ({text, h3}) =>
	h3 ?
		<h3 className='titlebar__title'>{text}</h3> :
		<h1 className='titlebar__title'>{text}</h1>

Components.Title = Title
Components.Title.displayName = 'Title';
Title.propTypes = {
	text: PropTypes.string,
	h3: PropTypes.bool
}

Components.isLoading = ({loading}) => loading

export const Empty = () => <span></span>
Components.Empty = Empty
Components.Empty.displayName = 'Empty';

export const Loading = () =>
	<Spinner totalCenter />
Components.Loading = Loading
Components.Loading.displayName = 'Loading';

// SO MUCH MORE AWESOME:
export const loadingWrapperHOC = (loading) => branch(
	loading,
	renderComponent(Components.Loading)
)
Components.loadingWrapperHOC = loadingWrapperHOC

// old way: this needs to be BELOW the loading definition
export const loadingWrapper = branch(
	Components.isLoading,
	renderComponent(Components.Loading)
)
Components.loadingWrapper = loadingWrapper

/**
* UpdateStateFromForm
*
*   To add:
*
*       Common.UpdateStateFromForm,
*
*	Then add each field you want to work with:
*
*		withState('name', 'setName', ''),
*
*	Then you can just:
*
*		<Form.Input
*			label='Name field'
*			name='name'
*			value={name}
*			onChange={updateStateFromForm}
*		/>
*/
export const UpdateStateFromForm = withHandlers({
	updateStateFromForm: (props) => ({target}) => {
		const key = 'set' + _.capitalize(target.name)
		if (_.has(props, key)) {
			const value = target.type === 'checkbox' ? target.checked : target.value
			props[key](value)
		}
	},
})
Components.UpdateStateFromForm = UpdateStateFromForm

/**
* UpdateStateFromForm
*
*   Use when you have data like: {name: 'jim'} or {primary_name: {first: 'test', last: 'nice'}}}
*
*   To add:
*
*       withState('model', 'setModel', new Backbone.Model()),
*       Common.UpdateStateFromModel,
*
*	Then you can just:
*
*		<Form.Input
*			label='Field name'
*			name='name'
*			value={model.get('name')}
*			onChange={updateStateFromModel}
*		/>
*
*   or:
*
*		<Form.Input
*			label='Field name'
*			name='primary_name.given_name'
*			value={model.get('primary_name').given_name}
*			onChange={updateStateFromModel}
*		/>
*/
export const UpdateStateFromModel = withHandlers({
	updateStateFromModel: (props) => (store = {}) => ({target}) => {
		let value = '';

		if (target.type === 'checkbox') {
			value = target.checked
		} else if (target.type === 'radio') {
			if (target.value === 'true' || target.value === 'false') {
				value = target.value === 'true' ? true : false
			} else {
				value = target.value
			}
		} else {
			value = target.value
		}

		const model = props.model

		// this allows us to set nested values too
		// and since we no longer care much about backend events, its ok to skip the 'real' setter
		_.set(model, 'attributes.' + target.name, value)
		if (!_.isEmpty(store)) {
			store.setKey(target.name, value)
		}
		props.setModel(model)
	},
})
Components.UpdateStateFromModel = UpdateStateFromModel

/**
* UpdateStateObjectFromModel
*
*   Use when you have data like: {name: {fname:'dragon', lname: 'hk'}}
*
*	Then you can just:
*
*		<Form.Checkbox
*			text='Document created'
*			name='name.fname'
*			value={mode.get('name').fname}
*			onChange={updateStateObjectFromModel}
*		/>
*
*   or:
*
*/

export const UpdateStateObjectFromModel = withHandlers({
	updateStateObjectFromModel: (props) => ({target}) => {
		const value = target.type === 'checkbox' ? target.checked : target.value
		let model = props.model

		let isNested = target.name.split('.')

		if (isNested.length !== 2) {
			console.warn('UpdateStateObjectFromModel only works with Object values, eg: name.first_name, passes as name.first_name')
			return false;
		} else {
			let data = model.get(isNested[0]);

			data[isNested[1]] = value
			model.set(Object.assign({}, data))
		}

		props.setModel(model)
	},
})

Components.UpdateStateObjectFromModel = UpdateStateObjectFromModel;

/**
* UpdateStateArrayFromModel
*
*   Use when you have data like: {events: ['a', 'b', 'c']}
*
*	Then you can just:
*
*		<Form.Checkbox
*			text='Document created'
*			name='events.a'
*			value='events.a'
*			checked={_.includes(model.get('events'), 'a')}
*			onChange={updateStateArrayFromModel}
*		/>
*
*   or:
*
*/
export const UpdateStateArrayFromModel = withHandlers({
	updateStateArrayFromModel: (props) => ({target}) => {
		const value = target.type === 'checkbox' ? target.checked : target.value
		let model = props.model
		// this allows us to set nested values too
		// and since we no longer care much about backend events, its ok to skip the 'real' setter

		let isNested = target.name.split('.')

		// for select mostly:
		if (isNested.length !== 2) {
			let data = model.get(target.name);

			if (!_.includes(data, value)) {
				data.push(value)
			}

			model.set(target.name, data)
		// for checkboxes mostly:
		} else {
			let data = model.get(isNested[0]);

			if (value) {
				!_.includes(data, isNested[1]) && data.push(isNested[1])
			} else {
				data = _.without(data, isNested[1])
			}

			model.set(isNested[0], data)
		}

		props.setModel(model)
	},
})
Components.UpdateStateArrayFromModel = UpdateStateArrayFromModel

const ToggleEnhancer = compose(
	withState('toggleClass', 'setToggleClass', 'is-gone'),
	withState('toggleboxClass', 'setToggleBoxClass', 'togglebox togglebox--base'),
	withHandlers({
		toggle: ({setToggleBoxClass, setToggleClass, toggleClass, toggleboxClass}) => () => {
			setToggleClass(App.Common.Utils.toggleClasses(toggleClass))
			setToggleBoxClass(App.Common.Utils.toggleClass(toggleboxClass))
		},
	}),
)

const processIconName = (icon) => {
	return _.isObject(icon) ? icon.prefix + icon.name : '#icon-' + icon;
}

export const ToggleWrapper = ({description, title, icon, HTMLTitle, panelOpenClass = ''}) => ToggleEnhancer(({
	toggle,
	toggleClass,
	toggleboxClass,
	children,
	defaultExpanded
}) => {

	if (defaultExpanded) {
		if (!toggleboxClass.match(/is-expanded/g)) {
			toggleboxClass += ' is-expanded'
		} else {
			toggleboxClass = toggleboxClass.replace('is-expanded', '')
		}

		if (toggleClass === 'is-gone') {
			toggleClass = 'is-expanded'
		} else {
			toggleClass = 'is-gone'
		}
	}

	return (
		<div className={`${toggleboxClass} ${panelOpenClass}`}>
			<div className='slat slat--invisible slat--h-collapse-medium-down'>
				<div className='slat__icon'>
					<Svg.Base
						className='icon icon--medium'
						iconName={processIconName(icon)}
					/>
				</div>
				<div className='slat__content slat__content--visible'>
					<div className='slat__title togglebox__target' onClick={toggle}>
						{HTMLTitle ?
							<div style={{'display': 'inline-block'}}>{HTMLTitle}</div> :
							<h3 className='u-box__inline'>{title}</h3>
						}
						<div className='togglebox__icon'>
							<Svg.Base
								className='icon icon--micro'
								iconName='#icon-chevronup--menu'
							/>
						</div>
					</div>
					<div className='slat__text'>
						<span>{description}</span>
					</div>
					<div className={`${panelOpenClass ? 'is-expanded' : toggleClass}`}>
						{children}
					</div>
				</div>
			</div>
		</div>
	)
}
)
Components.ToggleWrapper = ToggleWrapper

export const SimpleToggleWrapper = ({Toggler, ...props}) => ToggleEnhancer(({toggle, toggleClass, toggleboxClass, label, children}) => {
	return (
		<div className={toggleboxClass}>
			<div onClick={toggle}>
				<Grid container wrap='nowrap' justify='space-between'>
					{label && (
						<Grid item xs={3} sm={6}>
							<div className='titlebar titlebar--noborder titlebar--top-collapse' >
								<h2 className='titlebar__title titlebar--abbr' >{label}</h2>
							</div>
						</Grid>
					)}

					<Grid item xs={12} sm={6}>
						<div style={label && {marginLeft: '80%'}}>
							<Toggler {...props} />
						</div>
					</Grid>
				</Grid>
			</div>
			<div className={`${toggleClass}`}>
				{children}
			</div>
		</div>
	)
}

)
Components.SimpleToggleWrapper = SimpleToggleWrapper

export const Closeable = class Closeable extends React.Component {
	static propTypes = {
		noClose: PropTypes.bool,
		callback: PropTypes.func
	}

	componentDidMount() {
		// let escCheck = (e) => {
		// 	let k = e.keyCode || e.which

		// 	if (k === 27) { // esc
		// 		this.closeModal()
		// 	}
		// }

		// if (!this.props.noClose) {
		// 	$(document).on('keyup.closable', escCheck)
		// }
	}

	closeModal = () => {
		App.actions.unmountReactComponent('modal')

		if (this.props.callback) {
			this.props.callback()
		}

		// $(document).off('.closable');
	}

	render() {
		if (!this.props.noClose) {
			return (
				<div onClick={this.closeModal} className='modal__close' data-modal-close=''>
					<Components.SvgIcon className='icon icon--tiny' iconName='images/sprites.svg#icon-cross' />
				</div>
			)
		}

		return (
			<div></div>
		)
	}
};
Components.Closeable = Closeable

/**
* Modal
* - generate markup for a containing modal
*/
export const Modal = class Modal extends React.Component {
	static propTypes = {
		header: PropTypes.bool,
		childProps: PropTypes.shape({
			base: PropTypes.bool,
			restricted: PropTypes.bool,
			noClose: PropTypes.bool,
		}),
		component: PropTypes.any,
		callback: PropTypes.func,
		customModal: PropTypes.bool,
		wide: PropTypes.bool
	}

	componentDidMount() {
		// $(this.modal).addClass('is-visible')
		this.modal.classList.add('is-visible')

		// implement trigger, so that this modal looks the same as non-react ones
		this.trigger = (event) => {
			if (event === 'close' && this.closable) {
				this.closable.closeModal()
			}
		}
	}

	render() {
		let baseModalInner = 'modal__inner'
		let header = this.props.header ? ' has-header' : ''
		let base = this.props.childProps.base ? ' modal__inner--base' : ''
		let restricted = this.props.childProps.restricted ? ' modal__inner--restricted' : ''

		let ComponentToShow = this.props.component

		return (
			this.props.customModal ?
				<ComponentToShow {...this.props.childProps} /> :
				<section ref={(ref) => {this.modal = ref}} className={this.props.wide ? 'modal modal--wide' : 'modal'}>
					<div className={baseModalInner + header + base + restricted}>
						<Components.Closeable
							noClose={this.props.childProps.noClose}
							ref={(ref) => {this.closable = ref}}
							callback={this.props.callback}
						/>

						<div id='modal-content'>
							<ComponentToShow {...this.props.childProps} />
						</div>
					</div>
				</section>
		);
	}
};
Components.Modal = Modal

export const ModalChoice = ({
	text,
	successText = 'Delete',
	handleSuccess,
	handleCancel = () => App.radio.trigger('modal:hide'),
	handleClose,
}) => {
	// console.log('rest', rest)

	return (
		<div>
			<div className='modal__inner--icon'>
				<div className='modal__icon'>
					<Svg.Info size='large' />
				</div>
				<div className='modal__content'>
					<p>
						{text}
					</p>
					<div className='actions'>
						<ComponentButton id='yes-button' onClick={() => handleSuccess({handleClose})} color='primary'>{successText}</ComponentButton>
						<ComponentButton id='no-button' onClick={handleCancel} simple disableRipple color='primary'>Cancel</ComponentButton>
					</div>
				</div>
			</div>
		</div>
	)
}

Components.ModalChoice = ModalChoice
Components.ModalChoice.displayName = 'ModalChoice';
ModalChoice.propTypes = {
	text: PropTypes.string,
	successText: PropTypes.string,
	handleSuccess: PropTypes.func,
	handleCancel: PropTypes.func
}

/**
* DEPRECATED: use BASE from common_svgs
*/
export const SvgIcon = (props) =>
	<svg {..._.omit(props, 'iconName')}>
		<use xlinkHref={props.iconName}></use>
	</svg>

Components.SvgIcon = SvgIcon
Components.SvgIcon.displayName = 'SvgIcon';
SvgIcon.propTypes = {
	iconName: PropTypes.string
}

export const SearchBox = class SearchBox extends React.Component {
	static propTypes = {
		handleSearch: PropTypes.func.isRequired,
		label: PropTypes.string,
		staticSearch: PropTypes.bool,
		resetCollection: PropTypes.any,
		placeholder: PropTypes.string
	}

	static defaultProps = {
		staticSearch: false
	}

	constructor(props) {
		super(props)

		this.state = {
			search: this.props.initialField || ''
		}
	}

	handleNewCharacter = (event) => {
		this.setState({
			search: event.target.value
		}, () => {
			// reset when all text is cleared
			if (this.state.search === '') {
				// simulate enter press
				this.handleKeyPress({charCode: 13})
			}
		})
	}

	handleSearch = () => {
		this.props.handleSearch(this.state.search)
	}

	handleReset = () => {
		this.setState({search: ''});
		this.props.handleSearch('');
	}

	handleKeyPress = (event) => {
		if (event.charCode === 13) {
			if (_.isEmpty(this.state.search)) {
				if (_.isFunction(this.props.resetCollection)) {
					this.props.resetCollection()
				} else {
					console.warn('resetCollection is required function as props for reset');
				}
				return;
			}
			if (this.state.search) {
				this.props.handleSearch(this.state.search,this.props.additionalUrl)
			}
		}
	}

	render() {
		return (
			<div className='searchbar__wrapper'>
				{this.props.label &&
					<label>{this.props.label}</label>
				}

				<div className='searchbar searchbar--icon'>
					<Form.Input
						name='search-box'
						wrapperClass='searchbar__innerwrapper'
						value={this.state.search}
						className='searchbar__input'
						onChange={this.handleNewCharacter}
						onKeyPress={this.handleKeyPress}
						placeholder={this.props.placeholder || 'Search'}
					/>

					<button className='searchbar__action float-left' onClick={this.handleSearch}>
						<Svg.Search />
					</button>

					{this.state.search !== '' &&
						<button className='searchbar__action float-right' onClick={this.handleReset}>
							<Svg.Cross />
						</button>
					}
				</div>
			</div>
		)
	}
};
Components.SearchBox = SearchBox

export const RemoveIcon = ({handleClick}) =>
	<label onClick={handleClick}>
		<Svg.Cross />
	</label>

Components.RemoveIcon = RemoveIcon
Components.RemoveIcon.displayName = 'RemoveIcon';
RemoveIcon.propTypes = {
	handleClick: PropTypes.func
}

/*
*	Header
*	 - Simple header
*/
export const HeaderSimple = ({title}) =>
	<div>
		<h4 className='card__title'>
			{title}
		</h4>
	</div>

Components.HeaderSimple = HeaderSimple
Components.HeaderSimple.displayName = 'HeaderSimple';
HeaderSimple.propTypes = {
	title: PropTypes.string
}

export const Tooltip = class ComponentsTooltip extends React.Component {
	static propTypes = {
		text: PropTypes.string,
		children: PropTypes.any
	}

	constructor(props) {
		super(props)

		this.state = {
			key: App.Common.Utils.generateId(),
			isOpen: false
		}

		this.handleClick = this.handleClick.bind(this)
	}

	handleClick() {
		this.setState({
			isOpen: this.state.isOpen === '' ? 'open f-open-dropdown' : ''
		})
	}

	componentDidMount() {
		App.radio.trigger('activate:dropdowns', this.$el)
	}

	render() {
		return (
			<span>&nbsp;
				<Components.SvgIcon
					className='icon icon--micro u-color__dl-basalt u-colorfill__dl-white u-cursor__pointer'
					data-dropdown={this.state.key + '-tip'}
					data-options='is_hover:true'
					aria-haspopup='true'
					iconName='#icon-info--mono'
				/>
				<div>
					<div
						id={this.state.key + '-tip'}
						className={'dropdown dropdown--nopip dropdown--tooltip ' + this.state.isOpen}
						data-dropdown-content=''
					>
						<div
							className='dropdown--tooltip__icon'
							data-dropdown={this.state.key + '-tip'}
							onClick={this.handleClick}
						>
							<Components.SvgIcon
								className='icon icon--tiny icon--hover'
								iconName='#icon-cross'
							/>
						</div>
						<span> {this.props.text}{this.props.children}</span>
					</div>
					<div className='dropdown--tooltip__wrapper'></div>
				</div>
			</span>
		)
	}
}
Components.Tooltip = Tooltip

/*
* Button
* - Creates as many buttons as you want!
*  Example usage:
*	<Common.Button
*		btnClass={['class1']}
*		id={['id1']}
*		callback={[()=>{}]}
*		text={['text1']}
*		showBtn={[true]}
*	/>
*
* @memberof Common.Components
* @param {Object}
* @param {String} param.btnClass      - Class attribute of the button.
* @param {String} param.id            - ID attribute of the button.
* @param {String} param.text          - Button's innerText.
* @param {String} param.callback      - Your callback function to fire on `onClick` event.
* @param {Boolean} param.showBtn      - Show/hide button.
* @return {Function}  Stateless function
*/
export const Button = (param = Mandatory()) => {
	console.warn('DEPRECATED: common/button - use a buttom from ./button folder')

	// Check minimum required params are passed
	if (_.keys(param).length < 4) {
		console.warn(`Missing parameter(s). Check the Component calling <Common.Button/>`)
	}

	const {color, id, onClick, icon, iconClass, iconName, text, showBtn, centered, inlineBlock, disabled} = param
	let actions = 'actions'
	actions += centered ? ' actions--centered' : ''
	actions += inlineBlock ? ' actions--inlineblock' : ''

	return (
		<div className={actions}>
			{ // NOTE: You may map over anything, i just went with id.
				_.map(id, (id, idx) => (
					_.isUndefined(showBtn) ?
						<ComponentButton key={id}
							color={color[idx]}
							id={id}
							disabled={disabled !== undefined && disabled.length >= idx ? disabled[idx] : false}
							onClick={onClick[idx]}
						>
							{icon ?
								<Components.SvgIcon className={iconClass[idx]} iconName={iconName[idx]} /> :
								null
							}
							{text[idx]}
						</ComponentButton> :
						showBtn[idx] ?
							<ComponentButton key={id}
								color={color[idx]}
								id={id}
								disabled={disabled !== undefined && disabled.length >= idx ? disabled[idx] : false}
								onClick={onClick[idx]}
							>
								{icon ?
									<Components.SvgIcon className={iconClass[idx]} iconName={iconName[idx]} /> :
									null
								}
								{text[idx]}
							</ComponentButton> : null
				)
				)
			}
		</div>
	)
}

Button.propTypes = {
	showBtn: PropTypes.any,
	onClick: PropTypes.any,
}
Components.Button = Button
Components.Button.displayName = 'ComponentsButton';

export const StyledTitle = (boldTitle, normalTitle) => <div><b>{boldTitle} </b> - {normalTitle}</div>
StyledTitle.propTypes = {
	boldTitle: PropTypes.string,
	normalTitle: PropTypes.string,
}
StyledTitle.displayName = 'StyledTitle';

export const BoldTitle = ({text}) => {
	return <strong>{text} </strong>
}

BoldTitle.propTypes = {
	text: PropTypes.string
}

export const ErrorMessage = ({message}) => <span style={{color: 'red', display: 'block', paddingBottom: 15}}>{message || 'Error '}</span>
ErrorMessage.propTypes = {
	message: PropTypes.string
}

// test mock model
function MockModel(data) {
	this.attributes = data
}

MockModel.prototype.get = function(name) {
	return this.attributes[name]
};

MockModel.prototype.set = function(name, value) {
	this.attributes[name] = value
};
Components.MockModel = MockModel;

export default Components;
