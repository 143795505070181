import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import {withStyles} from '@material-ui/core/styles'

import iconWrapperStyle from '../assets/styles/wrapper/icon'
import Wrapper from './wrapper'

function IconWrapper(props) {
	const {
		children,
		classes,
		color,
		size,
		margin,
		isHover,
		className,
		onClick,
		...rest
	} = props

	const iconClasses = classNames({
		'material-icons': true,
		[classes.icon]: true,
		[classes.isHover]: isHover || onClick,
		[classes.iconColor + color]: color || 'inherit',
		[classes.iconSize + size]: size,
		[classes.marginRatio + margin]: margin,
		[className]: true
	})

	const wrapperProps = {
		display: 'flex',
		className: iconClasses,
		onClick: onClick,
		...rest
	}

	return (
		<Wrapper {...wrapperProps}>
			{children}
		</Wrapper>
	)
}

IconWrapper.propTypes = {
	classes: PropTypes.object.isRequired,
	color: PropTypes.oneOf([
		'inherit',
		'primary',
		'primaryLight',
		'white',
		'danger',
		'warning',
		false
	]),
	size: PropTypes.oneOf([
		'xs',
		'sm',
		'md',
		'lg',
		'xl',
		'hero', // larger than anyone as a hero
		false
	]),
	margin: PropTypes.oneOf([
		'sm',
		'lg',
		'none',
		false
	]),
	isHover: PropTypes.bool,
}

export default withStyles(iconWrapperStyle)(IconWrapper)
