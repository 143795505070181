import {
	get,
	each,
	omit,
	defaults,
	last,
	isFunction,
	bind,
	reduce,
	has,
	set,
} from 'lodash'
import Backbone from 'backbone'

import Radio from 'backbone.radio'

// console.log('Loading BASE APP - base');

Backbone.Radio = Radio

/**
* Base App
*
* @module App
* @namespace App
* @author Adam Tombleson
* @copyright Dragon Law HK
*
* Order of events on startup:
* ---------------------------
*
* App: before:start
* App: start (implementation)
* App: start (base app)

* App: sidebar:started
* App: post:auth:complete
* App: setup:user:modules:loaded
* App: load:startup:features
*
*/
let App = {
	modules: [],
	processes: {
		// you should override this if you want, in your implementing app
		preAuth: () => {
			App.log('load_event', {message: 'App.processes.preAuth'}, 3)

			// console.log('STARTIN PREAuth');
			const i18n = () => import('submodules/entities-mst/misc/i18n').then((store) => {
				const i18nStore = store.default.create()
				const domain = App.getConfig('domain')
				// console.log('store', store, i18nStore);

				return i18nStore.setup('en', domain).then(() => {
					// console.log('i18nStore.data', i18nStore.data);
					App.polyglot.extend(i18nStore.data)
				}).catch((error) => {
					// App.log('i18n_error', error, 'App', 1)
					App.actions.logError('i18n_error', 'Whoops! It seems like we are having trouble contacting our server. Please try again shortly.')
					console.error('Error getting i18n strings, perhaps API is down.');
				})
			})

			if (App.getConfig('auth.disableAPIKey')) {
				return i18n()
			}

			return App.stores.user.getApiToken(true).then(() => {
				return i18n()
			})
		},

		postAuth: function(data, disableExtraCaching) {
			App.log('load_event', {message: 'App.processes.postAuth'}, 3)

			let startProfileCache = () => {
				return new Promise(function(resolve) {
					App.log('app', 'Post auth', 'Base App', 3);

					// setup app according to user roles
					Backbone.Radio.request('role', 'setup');

					var reSetRights = function() {
						// console.log('Resetting rights');

						// setup the entitlements
						var entitlements = (data && data.primary_org && data.primary_org.balances) ? data.primary_org.balances : {};
						Backbone.Radio.request('right', 'setup', entitlements);
					};

					if (App.getConfig('disableExtraCaching')) {
						reSetRights();
						return resolve();
					}

					resolve()
				}).then(() => {
					if (!App.getConfig('disableExtraCaching')) {
						// cacheJuris
						return Backbone.Radio.request('jurisdiction', 'list')
					}

					return Promise.resolve()
				})
				// .catch((error) => {
				// 	App.log('error', error, 'Baseapp', 2);
				// })
			}

			// this is cool too though:
			// require(['modules/entitlement/app'], function() {
			// 	App.module('Entitlement').start(entitlements);
			// });

			return Promise.all([startProfileCache()])
		},

		afterSetup: () => Promise.resolve(),

		/**
		* When the user log's in
		*
		* Note, this is before the app modules load
		*/
		afterLoginInternal: (data) => {
			App.log('load_event', {message: 'App.processes.afterLoginInternal'}, 3)
			// console.log('========================================');

			App.processes.afterLogin && App.processes.afterLogin(data)

			App.stores.menu.showMenu('left')

			if (!App.partialLogin) {
				let user = App.stores.user
				App.processes.postAuth(data).then(() => {
					// return App.getConfig('whiteLabel') ? App.actions.getPartnerInfo() : false
					return false
				}).then((partner) => {
					if (!App.partialLogin) {
						// use user setting as default
						let version = data.app_version || 1
						let partnerIsActive = (partner && partner.get('namespace')) || false
						user.mode = 'standard'

						App.log('app', 'User.partner: ' + partner, 'BaseApp', 2);
						App.log('app', 'PartnerIsActive: ' + partnerIsActive, 'BaseApp', 2);

						if (App.getConfig('whiteLabel') && partnerIsActive) {
							// console.log('Partner info:', partner);

							version = '3'
							user.mode = 'partner'
							user.partner = partner
							// hrmm, how else can we do this?
							partner.id = data.primary_org._id
							partner.domain = partner.get('namespace')
							App.log('app', 'Partner: ' + partner, 'BaseApp', 2);
						}

						App.log('app', 'Version: ' + version, 'BaseApp', 2);
						App.log('app', 'User.mode: ' + user.mode, 'BaseApp', 2);
					}

					App.radio.trigger('post:auth:complete'); // not used in dte
				}).catch((error) => {
					App.log('error', error, 'BaseApp', 2);
				})
			}
		},

		beforeStart: () => {
			// console.log('BEFORE START - no logs yet')
			// App.log('load_event', {message: 'App.processes.beforeStart'}, 3)

			App.radio.on('message', function(message, mode) {
				let props = {
					message
				}

				if (mode === 'good') {
					props['good'] = true
				}

				if (mode === 'bad') {
					props['bad'] = true
				}

				if (mode === 'info') {
					props['info'] = true
				}

				App.actions.message(props)
			});

			/**
			* Reset Cache
			*
			* This is called in un-authed mode
			* So make sure that there is nothign that required authentiation here
			* Aka: No calls to the server.
			*
			*/
			App.radio.on('reset:cache', function() {
				App.log('Reseting user cache', 'Base App', 3);

				if (has(App, 'stores.general.shoppingCartEmpty')) {
					App.stores.general.shoppingCartEmpty()
				}

				App.actions.resetCache()

				// user.cacheClear()

				// clear the cache's, so the next call will update them
				Backbone.Radio.request('card', 'cache:reset')
				Backbone.Radio.request('guide:category', 'cache:reset')
				Backbone.Radio.request('org', 'payment:cache:reset')

				// clear notifications
				// App.radio.request('notification:reset');
			});

			/**
			 * When triggered displays an overview modal
			 *
			 * @param view View - View or ReactComponent to display
			 * @param callback function - Called when modal is closed
			 *
			 */
			App.radio.on('modal', function(view, callback, options) {
				// import {modalReact} from 'submodules/baseApp/common/utils/modal'
				import('submodules/baseApp/common/utils/modal').then((modalReact) => {
					App.cache.modal = modalReact.default(view, options, callback)
				})
			});

			App.radio.on('modal:hide', function() {
				App.actions.unmountReactComponent('modal')
			});

			/**
			 * Log an application error
			 *
			 * @param message string - Message to log
			 * @param data    object - Supplimental data
			 * @param safe    bool   - True to not rediect
			 */
			App.radio.on('error', function(message, data, safe) {
				console.trace()
				App.actions.logError('DEPRECATED ERROR FORMAT', {
					'useThisInstead': 'App.actions.logError(...',
					'doNotUse': 'App.radio.trigger(...',
				}, true)

				App.actions.logError(message, data, safe)
			})

			// NOTE: this code is duplicated from the common shortcut behavior
			// $(document).on('keydown.always', function(evt) {
			// 	var key = App.Common.Utils.radio.request('shortcut:check', evt);
			// 	if (key === undefined) {
			// 		return true;
			// 	}

			// 	let user = App.stores.user
			// 	let shift = evt.shiftKey || (window.event && window.event.shiftKey)
			// 	let ctrl = evt.ctrlKey || (window.event && window.event.ctrlKey)
			// 	let alt = evt.altKey || (window.event && window.event.altKey)

			// 	// console.log('key', key);
			// 	// console.log('evt', evt);
			// 	// console.log('alt', alt);

			// 	if (shift && key === 191) { // shift + ?
			// 		App.radio.trigger('modal', new App.Common.Views.Auto({
			// 			template: 'shortcuts',
			// 			isAdmin: user.isAdmin()
			// 		}));
			// 	}

			// 	if (ctrl && shift && key === 83) { // ctrl + shift + s
			// 		App.radio.request('user:settings', 'subscription')
			// 	}

			// 	// admin shortcuts
			// 	if (user && user.isAdmin()) {
			// 		if (ctrl && alt && shift && key === 65) { // ctrl + shift + a
			// 			App.radio.request('doctypeAdmin:list');
			// 		}
			// 	}
			// });

			/**
			 * Set the title of the page
			 *
			 * @param titleKey string - i18n key for document title or string
			 */
			App.radio.on('title', function(titleKey = '', options = {}) {
				App.actions.changeTitle(titleKey, options)
			})
		},

		start: () => {
			App.log('load_event', {message: 'App.processes.start'}, 3)

			// this will start a timer to show a warning if it takes too long
			App.loadTimer = setTimeout(function() {
				console.error('LOADING FAILED, will restart in 2 seconds.');
				App.actions.logError('loading_error', 'Loading took too long, restarting load');

				App.restart(2000);
			}, 30000);
			// }, App.reloadTimer);

			App.setHeader('APIVersion', App.getConfig('APIVersion'))

			// load some stores we need before app is ready
			return Promise.all([
				import('../entities-mst/user/user'),
				import('../../common/stores/general'),
				import('../entities-mst/misc/juri'),
				import('../entities-mst/misc/market'),
				import('../entities-mst/org/orgCollection/orgCollection'),
				import('../../common/stores/menu'),
				// import('org_entity'),
			]).then((results) => {
				// console.log('start results:', results);

				const [
					User,
					General,
					Juri,
					Market,
					Orgs,
					Menu
				] = results

				App.stores.user = User.default.create()
				// console.log('--======================= LOADING STORES', App.stores);

				// NOTE:
				// we can only create stores once
				// once they are made, we should NEVER re-assign these variables
				// meaning we should NEVER do this:
				// App.stores.orgs = Orgs.default.create()
				// because it will break the observables
				//

				App.stores.general = General.default.create()

				App.stores.orgs = Orgs.default.create()
				App.stores.menu = Menu.default.create()

				App.stores.juri = Juri.default.create()
				App.stores.markets = Market.default.create()

				return new Promise((resolve) => {
					if (App.getConfig('ajaxMode') === 'Axios') {
						// import('./common/setupAxios').then(resolve)
						resolve()
					} else {
						import('./common/setupBackbone').then(resolve)
					}
				})
			})
				.then(import('./auth/app'))

				// needs to be in callback cause stores is loaded late
				.then(() => App.stores.user.login())

				.then(() => {
					if (App.loadCustomMenus) {
						return App.loadCustomMenus()
					}
				})

				.then(() => {
					App.log('load_event', {message: 'App.processes.start - stage 2'}, 3)
					// App.log('Attempting to load:', App.adminModules, 'Main App', 2);
					const authModules = App.authModules || []

					let loadingModules = []
					each(App.adminModules.concat(App.userModules, authModules), (module) => {
						// https://webpack.js.org/api/module-methods/#import-
						loadingModules.push(module)
					})

					return Promise.all(loadingModules)
				})
				.then((results) => {
					App.log('load_event', {message: 'App.processes.start - stage 3'}, 3)
					delete App.adminModules; // hide the list
					each(results, (module) => {
						module.default.addMenus && module.default.addMenus(App.Menus)
					})

					App.log('load_event', {message: 'App.processes.start - stage 4', notes: 'Admin modules loaded'}, 3)

					// old:
					App.radio.trigger('setup:admin:modules:loaded')
					// new:
					App.processes.afterStart()

					// this will stop the warnings from appearing about loading not working
					clearTimeout(App.loadTimer)
				})
		},

		// this should be updated by the implementing app:
		afterStart: () => console.warn('Error: calling default after start'),

		beforeLogout: () => Promise.resolve(),

		/**
		* Things to run after logout
		*
		* @todo note why is this in postprocess:
		* I think because this is generic reset stuff, that we need to call when
		* using the multiple auth and auth endpoint switching
		*
		* also note: this is called before the app loads the initial login box
		* first it will check the token, if thats not good, it will run the unauth code
		* which calls this, then it shows the login box (or whatever, perhaps another unauthed route)
		* Before this is called, the session will take care of the auth stuff
		* Reset the app display stuff here
		*/
		postLogout: function(partial) {
			App.log('load_event', {message: 'App.processes.postLogout'}, 3)

			// old
			App.radio.trigger('pre:post:logout')
			// new
			App.processes.beforeLogout && App.processes.beforeLogout()

			App.stores.menu.hideMenu('left')

			// reset the content areas
			// App.pageRegion.reset();

			if (App.Menu) {
				// reset all sidebar classes
				App.Menu.radio.trigger('left:empty');
				App.Menu.radio.trigger('left:remove');
				App.Menu.radio.trigger('top:reset');

				App.Menu.radio.trigger('hide:right');
				App.Menu.radio.trigger('right:reset');
				App.Menu.stop();
			}

			// make sure all modules that are not defaults are stopped
			each(App.submodules, function(module) {
				if (!module.startWithParent) {
					module.stop();
				}
			});

			// clear all settings
			Backbone.Radio.request('perm', 'reset');

			// set as a blank user
			// App.Auth.radio.trigger('auth:permissions:start');

			Backbone.Radio.request('right', 'setup', []);

			App.radio.trigger('reset:cache');

			// load default roles
			Backbone.Radio.request('role', 'setup');

			Backbone.Radio.request('sidebar', 'cache:reset');

			if (App.socket) {
				App.socket.close();
			}

			// old
			// App.Auth.radio.trigger('logout:complete')
			// new
			App.processes.afterLogout && App.processes.afterLogout()
		},

		afterLogout: () => Promise.resolve(),
	},

	module(name, data) {
		// console.log('Module name:', name);
		// console.log('Module data:', data);

		if (!get(App, name)) {
			const newModule = Backbone.Radio.channel(name)
			let nameList = name.split('.')
			// console.log('name', name);
			newModule.moduleName = last(nameList)

			// console.log('Name:', name);

			let parent = false
			if (nameList.length > 1) {
				parent = nameList[nameList.length - 2]

				if (parent !== name) {
					// console.log('Parent:', parent);
					const parentExists = App[parent]
					if (parentExists) {
						// console.log('parentExists', parentExists);
						newModule.parent = parentExists
					}
				}
			}
			// console.log('Parent:', parent);

			// console.log('Registering new module:', newModule);
			// App.log('Registering new module', newModule, 'Base', 2);

			set(App, name, newModule)
			App.modules.push(newModule)
		} else {
			// console.log('Found module:', name);
			// return get(App, name)
		}

		const module = get(App, name)

		if (isFunction(data)) {
			// console.info('Function data:', name, data);
			// console.log('old module', module);
			data(module)
		} else {
			// console.info('Data is funny:', name, data);
			if (data && data.define) {
				bind(data.define, module)()
			}
		}

		// console.log('Returning module:', module);
		return module
	},

	// general app radio
	radio: Backbone.Radio.channel('app'),

	/**
	* This is run after start
	*
	*/
	setup(options) {
		let settings = options.config || {};
		// console.log('Starting app with settings: ', settings, {options});

		// setup defaults
		this.features = {
			// send data to Google Analytics
			analytics: {
				enabled: false,
			},

			// allow testing routes to be loaded
			testingRoutes: false,
		}

		this.settings = {
			// path prefix
			root: '/',
			// localhost
			API: '',
			// APIVersion: '0.10.0',
			// APIVersion: 'never',
			APIVersion: '0.20.0',
			APIPrefix: '',
			// use local document generation
			debugDocumentGeneration: false,
			// show extra guide debugging
			debugGuide: false,
			// 0 - Nothing (Only log errors)
			// 1 - Save to loging endpoint
			// 2 - Show in console
			// 3 - Do not show
			logDisplayLevel: 1, // display to console
			logSaveLevel: 1, // save to /events
			logLimit: 20,

			headers: {
				'AuthDomain': {
					key: 'DragonAuth0',
					value: false,
				},
				'UserDomain': {
					key: 'DragonPreference',
					value: false,
				},
				'FireballKey': {
					key: 'X-Dragon-Law-Dragonball',
					value: false,
					standard: true,
					auth: true,
				},
				'AppKey': { // eg: Billing, Zegal, DL2
					key: 'X-Dragon-Law-App',
					value: false,
					standard: true,
					auth: true,
				},
				// 'APIKey': { // for the aws rate limiting etc
				// 	key: 'X-Api-Key',
				// 	value: false,
				// 	standard: true,
				// 	auth: true,
				// },
				'Token': { // for non reg's users
					key: 'X-Dragon-Law-Token',
					value: false,
					standard: true, // this should be false, but dont have a good way to manage headers in calls yet
					auth: true,
				},
				'Username': {
					key: 'X-Dragon-Law-Username',
					value: false,
					standard: true,
					auth: true,
				},
				'APIVersion': {
					key: 'X-Dragon-Law-API-Version',
					standard: true,
					value: false,
				},
			},

			htmlIds: {
				main: 'glb-content',
				loading: 'glb-loading',
				message: 'glb-message',
				modal: 'glb-modal',
				extra: 'glb-extras',
			},

			v16mode: true,
		}

		this.baseUrl = window.location.href.split('?')[0].split('#')[0];

		// override defaults with config values
		// console.log('From file settings:', settings);
		this.settings = defaults(App.defaults, omit(settings, 'features'), this.settings);
		// console.log('After this.settings:', this.settings);
		this.features = defaults(settings.features, this.features)
		// console.log('this.features', this.features);

		delete App.defaults; // these have been moved into settings now

		this.API = this.settings.APIPort ? this.settings.APIRoot + ':' + this.settings.APIPort : this.settings.APIRoot;

		// add any prefix, perhaps we should add suffix too?
		this.API += this.settings.APIPrefix

		App.actions.resetCache()

		if (this.settings.logAppErrors) {
			this.attachErrorWatcher()
		}

		// old
		App.radio.trigger('setup:complete')
		// new
		App.processes.afterSetup()
	},

	attachErrorWatcher() {
		window.addEventListener('error', (event) => {
			// console.log('event', event);

			App.actions.logError('js_error', {
				user: App.stores.user.email,
				path: window.location.href,
				running_time: event.timeStamp,
				msg: event.message,
				stack: event.error.stack,
				type: event.type,
				filename: event.filename,
				status: 921,
			})
		})
	},

	start(options = {}, processes = {}) {
		App.processes.beforeStart()

		this.setup(options)

		App.Menus = []

		// store defaults (for MST usage)
		App.stores = {
			_models: {},
			_views: (self) => {
				return reduce(App.stores._viewList, (result, view) => {
					return Object.assign(result, view(self))
				}, {})
			},
			_actions: (self) => {
				return reduce(App.stores._actionList, (result, action) => {
					return Object.assign(result, action(self))
				}, {})
			},

			_viewList: [],
			_actionList: [],
		}

		if (processes.renderApp) {
			processes.renderApp()
		}

		return App.processes.start()
	},
}

// define some modules early
App.module('User')
App.module('Common')

export default App
