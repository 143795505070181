import isObject from 'lodash/isObject'
import isNumber from 'lodash/isNumber'
import get from 'lodash/get'

import Logger from 'src/submodules/entities-mst/misc/log'

/**
* Event logging system
*
*/
export default (App) => {
	/**
	* Registration of events to trigger on app.event
	*
	*/
	App.events = {
		registered: [],
		reset: () => {
			App.events.registered = []
		},
		register: (event) => {
			App.events.registered.push(event)
		}
	}

	/**
	* Log event to registerd integrations (eg: Mixpanel)
	*
	* Also logs to api by default
	*
	* @param {String} event name
	* @param {Object} event data
	* @param {Bool} (last param) false to disable api log

	* @returns {String}
	*/
	App.event = (...args) => {
		let logged = false

		App.events.registered.forEach((registeredEvent) => {
			// fire the registered event
			const eventFormattedData = registeredEvent(...args)

			// log to api too
			if (eventFormattedData) {
				if (!logged) {
					logged = true
					// console.log('Relogging event:', eventFormattedData.name, {index})
					App.log(eventFormattedData.name, {data: eventFormattedData.data})
				}
			}
		})

		// console.warn('EVENT:', args, {logged})

		// some things we never want to log,
		// they should have false as last arg
		const lastIndex = args.length - 1
		if (get(args, lastIndex) === false) {
			return
		}

		// console.log('Checking to see futher logging:', logged)

		// make sure we only log one copy of this to the event server
		if (!logged) {
			// console.log('args', args)

			// HACKS TO STOP LOGGING objects BEFORE THE REGISTER EVENTS IS DONE
			if (args[1]) {
				let changed = false

				if (!args[1].data) {
					args[1].data = {}
				}

				if (isObject(args[1].activity)) {
					changed = true
					args[1].data.activity = args[1].activity._id
					delete args[1].activity
				}

				if (isObject(args[1].model)) {
					changed = true
					args[1].data.model = args[1].model._id
					delete args[1].model
				}

				if (isObject(args[1].doc)) {
					changed = true
					args[1].data.doc = args[1].doc._id
					delete args[1].doc
				}

				if (isObject(args[1].doctype)) {
					changed = true
					args[1].data.doctype = args[1].doctype._id
					delete args[1].doctype
				}

				if (isObject(args[1].comment)) {
					changed = true
					args[1].data.comment = args[1].comment._id
					delete args[1].comment
				}

				if (changed === true) {
					args[1].data.raw = true
				}
			}

			App.log(...args)
		}
	}

	App.logger = Logger.create()

	/**
	* Log function.
	* Pass all messages through here so we can disable for prod
	*
	* @param messaage String
	* @param data
	* @param domain
	* @param level
	*/
	App.log = (name, data, domain, level = 2, mode = 'log') => {
		// console.log('name, data', name, data)
		if (level === -1) {
			console.trace() // super debug mode
		}

		if (!App.settings) {
			// console.warn('Trying to log too soon:', name);
			return
		}

		if (domain === undefined) {
			level = 1
		}

		if (isNumber(domain)) {
			level = domain
			domain = ''
		}

		// NOTE: cannot use 'App.getConfig' as we should
		// because getConfig does a log... lol. incomming-> Maximum call stack size exceeded
		if (level <= App.settings.logSaveLevel) {
			// console.log('Adding a log to the cache', App.logger);

			App.logger.add({
				name,
				data,
			})
		}

		if (level > App.settings.logDisplayLevel) {
			return;
		}

		var d = new Date();
		d = d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds() + ' - ';

		if (typeof name !== 'string') {
			// eslint-disable-next-line
			console[mode](d + 'Object logged (' + domain + ')', name);
		} else {
			name = d + (domain || false ? '(' + domain + ') ' : '') + name;
			// eslint-disable-next-line
			data ? console[mode](name, data) : console[mode](name);
		}
	}

	App.logGroup = function(title, mode = 1) {
		if (App.settings.logDisplayLevel < 1) {
			return;
		}

		if (!title) { // for auto closing.
			mode = 3
		}

		/* eslint-disable */
		switch (mode) {
			case 1:
				console.group(title); break
			case 2:
				console.groupCollapsed(title); break
			case 3:
				console.groupEnd(); break
			default:
				console.log(title)
		}
		/* eslint-enable */
	}

	console.todo = function(domain, text = '') {
		App.getConfig('debug') &&
			// eslint-disable-next-line
			console.log('%c %s %s %s', 'color: yellow; background-color: black;', '–', domain, text, '–');
	}

	console.important = function(domain, text = '') {
		App.getConfig('debug') &&
			// eslint-disable-next-line
			console.log('%c %s %s %s', 'color: brown; font-weight: bold; text-decoration: underline;', '–', domain, text + '– ')
	}

}
