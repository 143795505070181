// THIS IS THE FILE THAT IS LOADED BY LIKE EVERYTHING
// WHEN YOU DO AN:
// import App from 'app'
import App from 'submodules/baseApp/core'
import {WrapThemeProvider} from 'common/components/wrapThemeProvider'

import roleMap from './common/roleMap'

App.roleMap = roleMap

App.actions.setupStores = () => {
	App.stores.doctypeData = []

	return Promise.all([
		import('submodules/doctype-mst/doctypeCollection'),
		import('submodules/entities-mst/guide/guideCollection'),
		import('submodules/entities-mst/guide/guideCategory/guideCategoryCollection'),
		import('submodules/entities-mst/landing/landingCardCollection'),
		import('submodules/entities-mst/org/plan/planCollection'),
		import('submodules/entities-mst/activityTemplateCategory/activityTemplateCategoryCollection'),
		import('./modules/intercomAdmin/entities/store'),
		import('./modules/coupon/store'),
		import('submodules/entities-mst/user/userCollection'),
		import('submodules/entities-mst/user/sharedDocsCollection'),
		import('submodules/entities-mst/org/orgCollection/orgCollection'),
		import('src/modules/scribes/entities/scribe'),
		import('src/modules/labels/entities/collection'),
		import('submodules/entities-mst/org/credits/credits'),
		import('submodules/entities-mst/org/whitelabel/whitelabel'),
		import('submodules/entities-mst/guide/product'),
		import('submodules/entities-mst/products/productCollection'),
		import('submodules/entities-mst/namespace/namespace'),
		import('submodules/entities-mst/misc/juri'),
		import('submodules/entities-mst/misc/sso'),
		import('submodules/entities-mst/misc/market'),
		import('src/modules/workflowTemplates/entities/workflowTemplates'),
		import('submodules/entities-mst/companyType/companyTypeCollection')
	]).then((stores) => {
		let [
			DoctypeCollectionStore,
			GuideCollectionStore,
			GuideCategoryCollectionStore,
			LandingCollectionStore,
			PlanCollectionStore,
			ActivityTemplateCategoryStore,
			IntercomStore,
			CouponStore,
			UsersStore,
			SharedDocsStore,
			OrgsStore,
			ScribeStore,
			LabelsStore,
			CreditsStore,
			WhitelabelStore,
			ProductStore,
			ProductCollectionStore,
			NamespaceStore,
			JuriStore,
			SSOStore,
			MarketStore,
			WorkflowTemplateStore,
			CompanyTypeStore
		] = stores
		App.stores.doctype = DoctypeCollectionStore.collectionWithAdmin.create({})
		App.stores.guideStore = GuideCollectionStore.collectionWithAdmin.create({})
		App.stores.guideCategoryStore = GuideCategoryCollectionStore.collectionWithAdmin.create({})
		App.stores.landingStore = LandingCollectionStore.collectionWithAdmin.create({})
		App.stores.planStore = PlanCollectionStore.collectionWithAdmin.create({})
		App.stores.ATCStore = ActivityTemplateCategoryStore.collectionWithAdmin.create({})
		App.stores.IntercomStore = IntercomStore.collectionWithAdmin.create({})
		App.stores.CouponStore = CouponStore.collectionWithAdmin.create({})
		App.stores.UsersStore = UsersStore.collectionWithAdmin.create({})
		App.stores.SharedDocuments = SharedDocsStore.collectionWithAdmin.create({})
		App.stores.orgs = OrgsStore.OrgCollectionStoreWithAdmin.create({})
		App.stores.scribe = ScribeStore.default.create()
		App.stores.labels = LabelsStore.default.create()
		App.stores.creditsStore = CreditsStore.default.create()
		App.stores.whitelabelStore = WhitelabelStore.default.create()
		App.stores.productStore = ProductStore.default.create()
		App.stores.productCollectionStore = ProductCollectionStore.collectionWithAdmin.create({})
		App.stores.namespaceStore = NamespaceStore.collectionWithAdmin.create({})
		App.stores.juriStore = JuriStore.collectionWithAdmin.create({})
		App.stores.marketStore = MarketStore.collectionWithAdmin.create({})
		App.stores.ssoStore = SSOStore.collectionWithAdmin.create({})
		App.stores.workflowTemplateStore = WorkflowTemplateStore.default.create()
		App.stores.companyTypeStore = CompanyTypeStore.collectionWithAdmin.create({})
	})
}

// test environment doesn't have actions object
if (!App.actions) {
	App.actions = {}
}

App.actions.setupModals(WrapThemeProvider)

App.getStore = (name) => {
	const stores = {
		guides: App.stores.guideStore,
		guideCategories: App.stores.guideCategoryStore,
		orgs: App.stores.orgs
	}
	return stores[name]
}

export default App

//
