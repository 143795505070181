export default {
	module: 'orgs',
	actions: {
		list: true,
		edit: true,
		details: true,
		create: true,
		dynamicPaths: [
			{
				paths: {
					'/orgs/:id/details': true,
					'/orgs/:id/members': true,
					'/orgs/:id/cards': true,
					'/orgs/:id/credits': true,
					'/orgs/:id/documents': true,
					'/orgs/:id/subscriptions': true,
					'/orgs/:id/invoices': true,
				},
				keys: {
					':id': 2
				}
			},
			{
				paths: {
					'/orgs/edit/:id': true,
				},
				keys: {
					':id': 3
				}
			},
			{
				paths: {
					'/orgs/:id/details/edit': true,
				},
				keys: {
					':id': 2
				}
			},
			{
				paths: {
					'/orgs/:id/subscription/:sub_id': true
				},
				keys: {
					':id': 2,
					':sub_id': 4,
				}
			},
			{
				paths: {
					'/orgs/:id/card/:cardId': true
				},
				keys: {
					':id': 2,
					':cardId': 4,
				}
			},
			{
				paths: {
					'/orgs/:id/card/:cardId/update-email': true
				},
				keys: {
					':id': 2,
					':cardId': 4,
				}
			},
			{
				paths: {
					'/orgs/:id/subscriptions': true
				},
				keys: {
					':id': 2
				}
			},
			{
				paths: {
					'/orgs/:id/subscriptions/create': true
				},
				keys: {
					':id': 2
				}
			},
			{
				paths: {
					'/orgs/:id/subscriptions/create/:planCode/:trialDays': true
				},
				keys: {
					':id': 2,
					':planCode': 5,
					':trialDays': 6
				}
			},
			{
				paths: {
					'/orgs/:id/subscription/:subId/addPlan/:couponID': true
				},
				keys: {
					':id': 2,
					':subId': 4,
					':couponID': 6
				}
			},
			{
				paths: {
					'/orgs/:id/subscription/:subId/changePlan/:oldPlan/:couponID': true
				},
				keys: {
					':id': 2,
					':subId': 4,
					':oldPlan': 6,
					':couponID': 7
				}
			},
			{
				paths: {
					'/orgs/:id/subscription/:subId/addCoupon': true
				},
				keys: {
					':id': 2,
					':subId': 4
				}
			},
			{
				paths: {
					'/orgs/:id/documents': true
				},
				keys: {
					':id': 2,
				}
			},
			{
				paths: {
					'/orgs/:id/white-label': true
				},
				keys: {
					':id': 2,
				}
			},
			{
				paths: {
					'/orgs/:id/purchased-documents': true
				},
				keys: {
					':id': 2,
				}
			},
			{
				paths: {
					'/orgs/:id/purchased-documents/add': true
				},
				keys: {
					':id': 2,
				}
			},
			{
				paths: {
					'/orgs/:id/invoices/:receiptID': true
				},
				keys: {
					':id': 2,
					':receiptID': 4
				}
			},
			{
				paths: {
					'/orgs/:id/purchased-documents/add/:product_id/:product_name': true
				},
				keys: {
					':id': 2,
					':product_id': 5,
					':product_name': 6
				}
			},
			{
				paths: {
					'/orgs/:id/cards/create': true
				},
				keys: {
					':id': 2,
				}
			},
			{
				paths: {
					'/orgs/:id/member/create': true
				},
				keys: {
					':id': 2,
				}
			},
			{
				paths: {
					'/orgs/:id/member/create/:type': true
				},
				keys: {
					':id': 2,
					':type': 5,
				}
			},
			{
				paths: {
					'/orgs/:id/subscriptions/create/:plan/0': true
				},
				keys: {
					':id': 2,
					':plan': 5,
				}
			},
			
		]

	}
}
